import { createActions } from 'redux-actions';

const END_EXAM = 'END_EXAM';
const NEW_EXAM = 'NEW_EXAM';
const STOP_SCAN = 'STOP_SCAN';
const START_SCAN = 'START_SCAN';
const CANCEL_SCAN = 'CANCEL_SCAN';
const STORE_SERIES = 'STORE_SERIES';
const SELECT_SERIES = 'SELECT_SERIES';
const SET_ACTIVE_VALUE = 'SET_ACTIVE_VALUE';
const SELECT_NEXT_SERIES = 'SELECT_NEXT_SERIES';
const STORE_SERIES_FILES = 'STORE_SERIES_FILES';
const STORE_SERIES_FILES_BUFF = 'STORE_SERIES_FILES_BUFF';
const UPDATE_ACTIVE_SERIES = 'UPDATE_ACTIVE_SERIES';
const UPDATE_CUSTOM_ACTIVE_SERIES = 'UPDATE_CUSTOM_ACTIVE_SERIES';
const UPDATE_CUSTOM_PARAMETERS = 'UPDATE_CUSTOM_PARAMETERS';
const SELECT_PREVIOUS_SERIES = 'SELECT_PREVIOUS_SERIES';
const STOP_CAROUSEL_AUTOPLAY = 'STOP_CAROUSEL_AUTOPLAY';
const START_CAROUSEL_AUTOPLAY = 'START_CAROUSEL_AUTOPLAY';
const UPDATE_PRESSURE_INJECTOR = 'UPDATE_PRESSURE_INJECTOR';
const SET_CURRENT_SERIES_INDEX = 'SET_CURRENT_SERIES_INDEX';
const UPDATE_RECONSTRUCTION_SERIES = 'UPDATE_RECONSTRUCTION_SERIES';
const ADD_SCANNED_SERIES_ID = 'ADD_SCANNED_SERIES_ID';
const SET_PLAYED_SERIES_INDEX = 'SET_PLAYED_SERIES_INDEX';
const UPDATE_VALUE = 'UPDATE_VALUE';
const UPDATE_GANTRY_TILT = 'UPDATE_GANTRY_TILT';
const UPDATE_CANVAS_CONFIGS = 'UPDATE_CANVAS_CONFIGS';
const CLEAR_CANVAS_CONFIGS = 'CLEAR_CANVAS_CONFIGS';

const {
  endExam,
  newExam,
  stopScan,
  startScan,
  cancelScan,
  storeSeries,
  updateReconstructionSeries,
  selectSeries,
  selectNextSeries,
  storeSeriesFiles,
  storeSeriesFilesBuff,
  updateActiveSeries,
  updateCustomActiveSeries,
  updateCustomParameters,
  updateCanvasConfigs,
  clearCanvasConfigs,
  selectPreviousSeries,
  stopCarouselAutoplay,
  startCarouselAutoplay,
  updatePressureInjector,
  updateValue,
  setActiveValue,
  setCurrentSeriesIndex,
  addScannedSeriesId,
  setPlayedSeriesIndex,
  updateGantryTilt,
} = createActions({
  [END_EXAM]: () => ({}),
  [NEW_EXAM]: () => ({}),
  [STOP_SCAN]: () => ({}),
  [START_SCAN]: () => ({}),
  [CANCEL_SCAN]: () => ({}),
  [STORE_SERIES]: (protocol, series) => ({ protocol, series }),
  [UPDATE_RECONSTRUCTION_SERIES]: (series) => ({ series }),
  [STORE_SERIES]: (protocol, series) => ({ protocol, series }),
  [SELECT_SERIES]: (nextSeriesIndex) => ({ nextSeriesIndex }),
  [STORE_SERIES_FILES]: (data) => ({ data }),
  [STORE_SERIES_FILES_BUFF]: (data) => ({ data }),
  [UPDATE_ACTIVE_SERIES]: (field, value) => ({ field, value }),
  [UPDATE_CUSTOM_PARAMETERS]: (planningId, field, value) => ({
    planningId,
    field,
    value,
  }),
  [UPDATE_CANVAS_CONFIGS]: (value) => ({
    value,
  }),
  [CLEAR_CANVAS_CONFIGS]: (index, value) => ({
    index,
    value,
  }),
  [UPDATE_CUSTOM_ACTIVE_SERIES]: (field, value) => ({ field, value }),
  [SELECT_PREVIOUS_SERIES]: () => ({}),
  [STOP_CAROUSEL_AUTOPLAY]: () => ({}),
  [START_CAROUSEL_AUTOPLAY]: () => ({}),
  [UPDATE_PRESSURE_INJECTOR]: (field, value) => ({ field, value }),
  [UPDATE_VALUE]: (field, value) => ({ field, value }),
  [SET_ACTIVE_VALUE]: (id) => ({ id }),
  [SET_CURRENT_SERIES_INDEX]: (id) => ({ id }),
  [ADD_SCANNED_SERIES_ID]: (id) => ({ id }),
  [SET_PLAYED_SERIES_INDEX]: (id) => ({ id }),
  [UPDATE_GANTRY_TILT]: (value) => ({ value }),
});

export {
  newExam,
  NEW_EXAM,
  endExam,
  END_EXAM,
  stopScan,
  STOP_SCAN,
  startScan,
  cancelScan,
  CANCEL_SCAN,
  START_SCAN,
  storeSeries,
  STORE_SERIES,
  selectSeries,
  SELECT_SERIES,
  selectNextSeries,
  SELECT_NEXT_SERIES,
  storeSeriesFilesBuff,
  STORE_SERIES_FILES_BUFF,
  storeSeriesFiles,
  STORE_SERIES_FILES,
  updateActiveSeries,
  UPDATE_ACTIVE_SERIES,
  updateCustomActiveSeries,
  UPDATE_CUSTOM_ACTIVE_SERIES,
  updateCustomParameters,
  UPDATE_CUSTOM_PARAMETERS,
  selectPreviousSeries,
  stopCarouselAutoplay,
  STOP_CAROUSEL_AUTOPLAY,
  startCarouselAutoplay,
  START_CAROUSEL_AUTOPLAY,
  SELECT_PREVIOUS_SERIES,
  updatePressureInjector,
  UPDATE_PRESSURE_INJECTOR,
  setActiveValue,
  SET_ACTIVE_VALUE,
  updateCanvasConfigs,
  UPDATE_CANVAS_CONFIGS,
  clearCanvasConfigs,
  CLEAR_CANVAS_CONFIGS,
  setCurrentSeriesIndex,
  SET_CURRENT_SERIES_INDEX,
  updateReconstructionSeries,
  UPDATE_RECONSTRUCTION_SERIES,
  addScannedSeriesId,
  ADD_SCANNED_SERIES_ID,
  setPlayedSeriesIndex,
  SET_PLAYED_SERIES_INDEX,
  updateValue,
  UPDATE_VALUE,
  updateGantryTilt,
  UPDATE_GANTRY_TILT,
};
