import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.css';
import {
    hideNotification
  } from '../../actions';

class NotificationModal extends React.Component {

    render() {
        return (
        <Modal 
            open={this.props.notification.isOpened} 
            closeOnDimmerClick={false} 
            onClose={this.props.onClose} 
            size="small" 
            className="notification-modal"
            dimmer={'blurring'}
        >
            <Modal.Header></Modal.Header>
            <Modal.Content>
            <div className="table-content">
                {this.props.notification.text}
            </div>   
            <div className="content-action">
                <Button content="OK" onClick={() => this.props.notificationAction.hideNotification()} />
            </div>   
            </Modal.Content>
            <Modal.Actions></Modal.Actions>
        </Modal>
        );
    }
}

const mapStateToProps = ({ notification }) => ({ notification });
const mapDispatchToProps = dispatch => ({
    notificationAction: bindActionCreators({
        hideNotification
    }, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotificationModal);