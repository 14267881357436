import { handleActions } from 'redux-actions';
import { SAVE_TOOLTIPS } from '../actions';

const defaultState = { data: {} };

export default handleActions(
  {
    [SAVE_TOOLTIPS]: (state, { payload: tooltips }) => {
        let data = {};
        tooltips.forEach(element => {
            data = {
                ...data,
                [element.elementName]: element.text
            }
        });
        
        return {
            ...state,
            data
        };
    },
  },
  defaultState
);
