import React, { Component } from 'react';
import { connect } from 'react-redux';
import Syringe from './Syringe';
import ScanProgress from './ScanProgress';
import './style.css';

class ScanTiming extends Component {
  render() {
    const { name, partOfBody } = this.props.scanner.protocol;

    return (
      <div>
        <h6 as="h6">Protocol: {partOfBody}{name ? `: ${name}` : ''}</h6>
        <ScanProgress />
        <Syringe />
      </div>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });

export default connect(mapStateToProps)(ScanTiming);
