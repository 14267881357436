import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tab } from "semantic-ui-react";
import { changeActiveTab } from "../../actions";
import ThicknessTab from "./ThicknessTab";
import ImagingTab from "./ImagingTab";
import InjectorTab from "./InjectorTab";
import './style.css';

class ScannerTabs extends Component {
  state = { activeIndex: 0 };

  panes = [
    {
      menuItem: "Thickness/Speed",
      render: () => (
        <Tab.Pane>
          <ThicknessTab />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Imaging",
      render: () => (
        <Tab.Pane>
          <ImagingTab />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Pr. Injector",
      render: () => (
        <Tab.Pane className="injector-tab">
          <InjectorTab />
        </Tab.Pane>
      )
    }
  ];

  handleTabChange = (e, { activeIndex }) => {
    this.props.tabsAction.changeActiveTab(activeIndex);
  };

  render() {
    return (
      <div>
        <Tab
          panes={this.panes}
          activeIndex={this.props.tabs.activeIndex}
          onTabChange={this.handleTabChange}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ tabs }) => ({ tabs });
const mapDispatchToProps = dispatch => ({
  tabsAction: bindActionCreators({ changeActiveTab }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScannerTabs);
