import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateActiveSeries, updateValue } from '../../actions';
import { Modal, Button, Input, Dropdown } from 'semantic-ui-react';

class ParameterModal extends Component {
  state = { parameterValue: null };

  handleChange = (e, { value }) => this.setState({ parameterValue: value });

  renderInput = () => {
    const { type } = this.props.selectedParameter;

    if (type === 'dropdown') {
      return this.renderDropdownInput();
    } else if (type === 'number') {
      return this.renderNumberInput();
    } else {
      return this.renderTextInput();
    }
  };

  renderDropdownInput = () => {
    return (
      <Dropdown
        fluid
        onChange={this.handleChange}
        options={this.props.selectedParameter.options}
        placeholder="Choose an option"
        selection
        value={
          Number(this.state.parameterValue) ||
          Number(this.props.selectedParameter.value)
        }
      />
    );
  };

  renderTextInput = () => {
    return (
      <Input
        initialvalue=""
        fluid
        value={this.state.parameterValue || this.props.selectedParameter.value}
        onChange={this.handleChange}
      />
    );
  };

  clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  renderNumberInput = () => {
    const { field } = this.props.selectedParameter;

    const defaultValue = this.clamp(this.state.parameterValue || this.props.selectedParameter.value, 24, 70);

    if (field === 'fieldOfView') {
      return (
        <Input
          fluid
          min="24"
          max="70"
          type="number"
          defaultValue={defaultValue}
          // value={
          //   this.state.parameterValue || this.props.selectedParameter.value
          // }
          onChange={this.handleChange}
        />
      );
    }

    return (
      <Input
        fluid
        min="0"
        type="number"
        value={this.state.parameterValue || this.props.selectedParameter.value}
        onChange={this.handleChange}
      />
    );
  };

  onClose = () => {
    this.setState({ parameterValue: null });

    if (
      (this.props.selectedParameter.field === 'startLocation' &&
        !this.props.scanner.isStartLocation) ||
      (this.props.selectedParameter.field === 'endLocation' &&
        !this.props.scanner.isEndLocation)
    ) {
      const location =
        this.props.selectedParameter.field === 'startLocation'
          ? 'isStartLocation'
          : 'isEndLocation';
      this.props.scannerAction.updateValue(location, false);
    }

    this.props.onClose();
  };

  onSave = () => {
    const { parameterValue } = this.state;
    const { field } = this.props.selectedParameter;

    if (parameterValue && field === 'fieldOfView') {
      const clamped = this.clamp(parameterValue, 24, 70);
      this.props.scannerAction.updateActiveSeries(field, clamped);
    } else if (parameterValue) {
      this.props.scannerAction.updateActiveSeries(field, parameterValue);
    }

    if (
      (this.props.selectedParameter.field === 'startLocation' &&
        !this.props.scanner.isStartLocation) ||
      (this.props.selectedParameter.field === 'endLocation' &&
        !this.props.scanner.isEndLocation)
    ) {
      const location =
        this.props.selectedParameter.field === 'startLocation'
          ? 'isStartLocation'
          : 'isEndLocation';
      this.props.scannerAction.updateValue(location, true);
    }
    this.setState({ parameterValue: null });
    this.props.onClose();
  };

  render() {
    const { isModalOpen, selectedParameter } = this.props;

    return (
      <Modal
        className="scan-param-modal scanParam-modal-param"
        closeOnDimmerClick={false}
        dimmer={'blurring'}
        open={isModalOpen}
        onClose={this.onClose}
        size="mini"
        closeIcon
      >
        <Modal.Header>{selectedParameter.text}</Modal.Header>
        <Modal.Content>
          {this.renderInput()}
          <div className="modal-params-ctrls">
            <Button onClick={this.onClose} content="Cancel" />
            <Button content="Save" onClick={this.onSave} />
          </div>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = (dispatch) => ({
  scannerAction: bindActionCreators(
    { updateActiveSeries, updateValue },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParameterModal);
