import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';

class Guide extends Component {

    state = {
        step1: true,
        step2: false,
        step3: false
    }

    checkStep1Id = (prevProps) => {
        return new Promise((resolve, reject) => {
            if(JSON.stringify(prevProps.steps.info) !== JSON.stringify(this.props.steps.info)) {
                if(!this.props.steps.info 
                    || this.props.steps.info.patientId === '' 
                    || !this.props.steps.info.patientId
                    ) {
                    this.setState({step1: true}, () => resolve())
                } else {
                    this.setState({step1: false}, () => resolve())
                }
            } else {
                resolve()
            }
        })
    }
    
    checkStep1Name = (prevProps) => {
        return new Promise((resolve, reject) => {
            if(JSON.stringify(prevProps.steps.info) !== JSON.stringify(this.props.steps.info) && !this.state.step1) {
                if(!this.props.steps.info 
                    || this.props.steps.info.patientName === '' 
                    || !this.props.steps.info.patientName
                    ) {
                    this.setState({step1: true}, () => resolve())
                } else {
                    this.setState({step1: false}, () => resolve())
                }
            } else {
                resolve()
            }
        })
    }

    checkStep2 = () => {
        return new Promise((resolve, reject) => {
            if(!this.state.step1) {
                if(this.state.step2 && this.props.steps.protocol.id) {
                    this.setState({step2: false}, () => resolve())
                } else {
                    if(!this.state.step2 && !this.props.steps.protocol.id)
                        this.setState({step2: true}, () => resolve())
                    else
                        resolve()
                }
            } else {
                if(this.state.step2)
                    this.setState({step2: false}, () => resolve())
                resolve();
            }    
           
        })
    }

    checkStep3 = () => {
        if(!this.state.step1 && !this.state.step2) {
            if(this.state.step3 && this.props.steps.isScanned) {
                this.setState({step3: false})
            } else {
                if(!this.state.step3 && !this.props.steps.isScanned)
                    this.setState({step3: true})
            }
        }
    }

    componentDidUpdate(prevProps) {
        this.checkStep1Id(prevProps)
            .then(() => this.checkStep1Name(prevProps))
                .then(() => this.checkStep2())
                    .then(() => this.checkStep3())
    }

    render() {
        return (
            <React.Fragment>
                {this.state.step1 && this.props.type === 1 && <div id="guide-step1" className="guide">
                        Add Patient Information
                    </div>
                }
                {this.state.step2 && this.props.type === 2 && <div id="guide-step2" className="guide">
                        Select protocol
                    </div>
                }
                {this.state.step3 && this.props.type === 3 && <div id="guide-step3" className="guide">
                        Edit parameters and click Start Scan
                    </div>
                }
            </React.Fragment>
            
        );
    }
}

const mapStateToProps = ({steps}) => ({steps});

export default connect(
    mapStateToProps,
)(Guide);