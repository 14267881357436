import { createActions } from 'redux-actions';

const START_LOADING = 'START_LOADING';
const STOP_LOADING = 'STOP_LOADING';

const {
  startLoading,
  stopLoading
} = createActions({
  [START_LOADING]: () => ({}),
  [STOP_LOADING]: () => ({})
});

export {
  startLoading,
  START_LOADING,
  stopLoading,
  STOP_LOADING
};
