import { handleActions } from 'redux-actions';
import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../actions';

const defaultState = { 
    isOpened: false,
    text: '' 
};

export default handleActions(
  {
    [SHOW_NOTIFICATION]: (state, { payload: { text } }) => {
      return {
        ...state,
        isOpened: true,
        text
      };
    },
    [HIDE_NOTIFICATION]: state => {
      return {
        ...state,
        isOpened: false,
        text: ''
      };
    }
  },
  defaultState
);
