import BaseApiService from './base';

export class BacgroundImageApiService extends BaseApiService {
    // eslint-disable-next-line
    constructor() {
      super();
    }

    get() {
      return this.request({
        method: 'get',
        url: '/background-image',
      });
    }
}