import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updatePressureInjector } from '../../../actions';
import { Modal, Button, Input, Dropdown } from 'semantic-ui-react';

class InjectorParameterModal extends Component {
  state = { parameterValue: '' };

  handleChange = (e, { value }) => this.setState({ parameterValue: value });

  renderInput = () => {
    const { type } = this.props.selectedParameter;

    if (type === 'dropdown') {
      return this.renderDropdownInput();
    } else if (type === 'number') {
      return this.renderNumberInput();
    } else {
      return this.renderTextInput();
    }
  };

  renderDropdownInput = () => {
    return (
      <Dropdown
        fluid
        onChange={this.handleChange}
        options={this.props.selectedParameter.options}
        placeholder="Choose an option"
        selection
        value={this.state.parameterValue || this.props.selectedParameter.value}
      />
    );
  };

  renderTextInput = () => {
    return (
      <Input
        initialvalue=""
        fluid
        value={this.state.parameterValue || this.props.selectedParameter.value}
        onChange={this.handleChange}
      />
    );
  };

  renderNumberInput = () => {
    return (
      <Input
        fluid
        min="0"
        type="number"
        value={this.state.parameterValue !== '' ? this.state.parameterValue : ''}
        onChange={this.handleChange}
      />
    );
  };

  onClose = () => {
    this.setState({ parameterValue: this.props.selectedParameter.value })
    this.props.onClose();
  };

  onSave = () => {
    const { parameterValue } = this.state;
    const { field } = this.props.selectedParameter;

    if (parameterValue)
      this.props.scannerAction.updatePressureInjector(field, parameterValue);

    this.props.onClose();
  };

  componentDidUpdate(prevProps) {
    if (this.props.isModalOpen && !prevProps.isModalOpen)
      this.setState({ parameterValue: this.props.selectedParameter.value })
  }

  render() {
    const { isModalOpen, selectedParameter } = this.props;
    return (
      <Modal
        className="scan-param-modal injector-modal-param"
        closeOnDimmerClick={false}
        dimmer={'blurring'}
        open={isModalOpen}
        onClose={this.onClose}
        size="mini"
        closeIcon
      >
        <Modal.Header>{selectedParameter.text}</Modal.Header>
        <Modal.Content>
          {this.renderInput()}
          <div className="modal-params-ctrls">
            <Button onClick={this.onClose} content="Cancel" />
            <Button content="Save" onClick={this.onSave} />
          </div>
        </Modal.Content>
        <Modal.Actions>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ updatePressureInjector }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectorParameterModal);
