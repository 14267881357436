import { createActions } from 'redux-actions';

const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

const {
  showNotification,
  hideNotification
} = createActions({
  [SHOW_NOTIFICATION]: text => ({text}),
  [HIDE_NOTIFICATION]: () => ({})
});

export {
    showNotification,
    SHOW_NOTIFICATION,
    hideNotification,
    HIDE_NOTIFICATION
};
