import { handleActions } from 'redux-actions';
import { 
    PATIENT_NAME,
    PROTOCOL,
    EDIT_START_SCAN 
} from '../actions';

const defaultState = { 
  info: {},
  protocol: {},
  isScanned: false
};

export default handleActions(
  {
    [PATIENT_NAME]: (state, { payload: { info } }) => {
      return {
        ...state,
        info
      };
    },
    [PROTOCOL]: (state, { payload: { protocol } }) => {
      return {
        ...state,
        protocol
      };
    },
    [EDIT_START_SCAN]: (state, { payload: { isScanned } }) => {
      return {
        ...state,
        isScanned
      };
    },
  },
  defaultState
);
