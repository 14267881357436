import { handleActions } from 'redux-actions';
import { START_LOADING, STOP_LOADING } from '../actions';

const defaultState = { isLoading: false };

export default handleActions(
  {
    [START_LOADING]: state => {
      return {
        ...state,
        isLoading: true
      };
    },
    [STOP_LOADING]: state => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  defaultState
);
