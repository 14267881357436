export const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  adminAppUrl: `${process.env.REACT_APP_ADMIN_APP_URL}/#/token-login`,
  defaultAdminUser: 'admin',
  bodyParts: [
    {id:1, part: 'head', name: 'Head/Brain', drawEmpty: false, class: 'head-button' },
    {id:2, part: 'neck', name: 'Neck/Cervical', drawEmpty: false, class: 'neck-button' },
    {id:3, part: 'chest', name: 'Chest/Thoracic', drawEmpty: false, class: 'chest-button' },
    {id:4, part: 'upper-extremity', name: 'Upper Extremity', drawEmpty: false, class: 'upper-extremity-button' },
    {id:5, part: 'abdomen', name: 'Abdomen/Lumbar', drawEmpty: false, class: 'abdomen-button' },
    {id:6, part: 'pelvis', name: 'Pelvis', drawEmpty: false, class: 'pelvis-button' },
    {id:8, part: 'lower-extremity', name: 'Lower Extremity', drawEmpty: false, class: 'lower-extremity-button' },
  ]
};
