export default values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password too short (8 characters mininum)';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.username) {
    errors.username = 'Username is required';
  }
  if (!values.organization) {
    errors.organization = 'School/Organization is required';
  }
  if (!values.city) {
    errors.city = 'City is required';
  }
  if (!values.state) {
    errors.state = 'State is required';
  }
  if (!values.country) {
    errors.country = 'Country is required';
  }
  if (!values.separateSeat) {
    errors.separateSeat = 'Separate Seat is required';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm Password is required'
  }
  if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password and confirmation must match'
  }
  return errors;
};
