import { AuthApiService } from './auth';
import { ProtocolApiService } from './protocol';
import { SeriesApiServices } from './series';
import { TooltipApiService } from './tooltip';
import { CustomerApiService } from './customers';
import { AdminApiService } from './admin';
import { BacgroundImageApiService } from './background';
import { LogApiService } from './logs';

class ApiService {
  constructor() {
    this.auth = new AuthApiService();
    this.protocol = new ProtocolApiService();
    this.series = new SeriesApiServices();
    this.tooltip = new TooltipApiService();
    this.customer = new CustomerApiService();
    this.admin = new AdminApiService();
    this.background = new BacgroundImageApiService();
    this.log = new LogApiService();
  }
}

export const Api = new ApiService();
