import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import {
  canvasUpdateAutoview,
  canvasUpdateReconstruction,
  enablePlanslice,
  disablePlanslice,
  stopCarouselAutoplay,
  startCarouselAutoplay,
  updateValue,
  updateGantryTilt,
  updateCanvasConfigs,
} from "../../actions";
import "./style.css";
import { fabric } from "fabric";

class PlanSlices extends Component {
  state = {
    baseConfig: {
      width: 210,
      height: 200,
      left: 5,
      top: 5,
      fill: "rgba(0,0,0,0)",
      hasControls: true,
      hasRotatingPoint: true,
      selectable: true,
      cornerColor: "cyan",
      cornerSize: 8,
      transparentCorners: false,
      hasBorders: false,
      borderColor: "#ecf76c",
      rotatingPointOffset: 20,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      originX: "center",
      originY: "center",
      hide: false,
    },
  };
  start() {
    this.props.planSlicesAction.enablePlanslice(this.props.canvasId);
    this.props.scanActions.stopCarouselAutoplay();

    this.props.scanActions.updateValue("isStartLocation", false);
    this.props.scanActions.updateValue("isEndLocation", false);
  }

  stop() {
    this.props.planSlicesAction.disablePlanslice(this.props.canvasId);

    this.props.scanActions.updateValue("isStartLocation", true);
    this.props.scanActions.updateValue("isEndLocation", true);
  }

  rotateLeft = () => {
    let { canvas } = this.props.planSlices;

    canvas = canvas[this.props.canvasId];

    let object = canvas.getActiveObject();
    if (object) object = canvas.getObjects()[0];
    let curAngle = object.angle;

    this.props.scanActions.updateGantryTilt((curAngle - 5) % 360);
    object.rotate(curAngle - 5).setCoords();
    canvas.renderAll();

    if (this.props.canvasId === "autoView") {
      this.props.planSlicesAction.canvasUpdateAutoview(canvas);
    } else {
      this.props.planSlicesAction.canvasUpdateReconstruction(canvas);
    }
  };

  rotateRight = () => {
    let { canvas } = this.props.planSlices;

    canvas = canvas[this.props.canvasId];

    let object = canvas.getActiveObject();
    if (object) object = canvas.getObjects()[0];
    let curAngle = object.angle;

    this.props.scanActions.updateGantryTilt((curAngle + 5) % 360);
    object.rotate(curAngle + 5).setCoords();
    canvas.renderAll();

    if (this.props.canvasId === "autoView") {
      this.props.planSlicesAction.canvasUpdateAutoview(canvas);
    } else {
      this.props.planSlicesAction.canvasUpdateReconstruction(canvas);
    }
  };

  clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  reset = () => {
    const {
      activeSeries,
      currentSeriesIndexDisplayed,
      series,
      customConfig,
      slices,
    } = this.props.scanner;

    const canvas = document.getElementById("canvas");

    const isDisplayedLocalizer =
      currentSeriesIndexDisplayed === (series[0] && series[0]["id"]);
    const isLocalizer = activeSeries.scanType === "1";

    if (!isDisplayedLocalizer && !isLocalizer) return null;
    //initial values
    const maxW = 210;
    const minW = 50;

    const width = this.clamp(maxW * 0.8, minW, maxW);

    const config = customConfig && customConfig[0];
    const deleted = config && config.hide;

    const modified = config && config.hide ? false : true;

    if (!canvas && !deleted) return;

    this.props.scanActions.updateCanvasConfigs({
      ...this.state.baseConfig,
      width,
      top: this.state.baseConfig.top + this.state.baseConfig.height / 2,
      left: this.state.baseConfig.left + this.state.baseConfig.width / 2,
      hide: false,
      modified: false,
      reset: true,
      coords: undefined,
    });
  };

  render() {
    return (
      <div className="plan-slices">
        <Button size="mini" className="padding-ctrl " onClick={this.reset}>
          Reset
        </Button>
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = (dispatch) => ({
  scanActions: bindActionCreators(
    {
      stopCarouselAutoplay,
      startCarouselAutoplay,
      updateValue,
      updateGantryTilt,
      updateCanvasConfigs,
    },
    dispatch
  ),
  planSlicesAction: bindActionCreators(
    {
      canvasUpdateAutoview,
      canvasUpdateReconstruction,
      enablePlanslice,
      disablePlanslice,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSlices);
