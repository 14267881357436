import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { loginUser } from '../actions';
import { Api } from '../services/api';
import RegisterComponent from '../components/register/Register';

class Register extends Component {

  state = {
    options: []
  }

  onSubmit = async values => {
    const response = await Api.auth.register(values);

    if (response.error) {
      toastr.error('Error', response.error);
    } else {
      this.props.userAction.loginUser(response.data);
      this.props.history.push('/');
    }
  };

  getOrganizations = async () => {
    const response = await Api.customer.getAll();

    if (response.error) {
      toastr.error('Error', response.error);
    } else {
      const options = [];
      response.data.forEach(element => {
          options.push({
            key: element.id,
            value: element.id,
            text: element.name
          });

          this.setState({options})
      });
    }
  }

  componentDidMount() {
    this.getOrganizations();
  }

  render() {
    return (
      <RegisterComponent
        onSubmit={this.onSubmit}
        options={this.state.options}
        onRegisterClick={this.onRegisterClick}
      />
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({
  userAction: bindActionCreators({ loginUser }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
