import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Loader } from 'semantic-ui-react';

class Loading extends Component {
  render() {
    return (
      <Modal open={this.props.loading.isLoading} basic dimmer="blurring">
        <Modal.Content>
          <Loader active={this.props.loading.isLoading} content="Loading" size="large" />
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(({ loading }) => ({ loading }))(Loading);
