import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image, Header, Grid } from 'semantic-ui-react';
import { storeSeries, showNotification, updateValue, updateActiveSeries } from '../../actions';
import { Api } from '../../services/api';
import ProtocolsModal from './ProtocolsModal';
import humanBodyImage from '../../images/human-body.png';
import './style.css';
import { config } from '../../config/index';

const SCANNABLE_TYPE = 'Scannable';

class PatientProtocols extends Component {
  state = {
    modalOpen: false,
    protocols: []
  };

  onBodyPartClick = async bodyPart => {
    const protocols = await Api.protocol.getByBodyPart(bodyPart);
    if (protocols.data && protocols.data.length)
      this.setState({
        modalOpen: true,
        protocols: protocols.data
      });
    else
      this.props.notificationAction.showNotification('No protocols for the selected part of body!')
  };

  handleClose = () => {
    this.props.scannerAction.updateValue('isStartLocation', false)
    this.props.scannerAction.updateValue('isEndLocation', false)
    this.setState({ modalOpen: false });
  };

  onProtocolClick = async protocol => {
    this.setState({ modalOpen: false });

    const series = await Api.protocol.getSeries(protocol.id);
    const { data } = series.data;
    const preparedSequences = [];

    this.props.scannerAction.updateValue('isStartLocation', false)
    this.props.scannerAction.updateValue('isEndLocation', false)

    if (data)
      data.forEach((el, key) => {
        if (el.type === SCANNABLE_TYPE) {
          preparedSequences.push(el);
        }
      })

    if (preparedSequences && preparedSequences.length) {
      this.props.scannerAction.storeSeries(protocol, preparedSequences);
    } else {
      this.props.scannerAction.updateActiveSeries('sliceThickness', '');
      this.props.notificationAction.showNotification('No sequences for the selected protocol!');
    }
  };

  render() {
    const { isRunning } = this.props.scanner.scan;

    return (
      <div id="protocols">
        <Header
          as="h5"
          textAlign="center"
          content="PATIENT PROTOCOL"
        />

        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column className="image-section">
              <Image src={humanBodyImage} className="human-body-image" />
            </Grid.Column>
            <Grid.Column className="ptcls-section">
              {
                config.bodyParts.map(part => {
                  if (part.drawEmpty) {
                    return <span size="mini" key={`protocol-${part.id}`}></span>
                  } else {
                    return <span
                      size="mini"
                      className={`pointer ${part.class}`}
                      disabled={isRunning}
                      key={`protocol-${part.id}`}
                      onClick={() => !isRunning && this.onBodyPartClick(part.part)}
                    >
                      {part.name}
                    </span>
                  }
                })
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ProtocolsModal
          onClose={this.handleClose}
          modalOpen={this.state.modalOpen}
          protocols={this.state.protocols}
          onProtocolClick={this.onProtocolClick}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ storeSeries, updateValue, updateActiveSeries }, dispatch),
  notificationAction: bindActionCreators({ showNotification }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientProtocols);
