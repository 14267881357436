import React from 'react'
import {
    Button,
    Input,
    Message
  } from 'semantic-ui-react';
import './NewPassword.css'
import { Api } from '../../../services/api';
import { toastr } from 'react-redux-toastr';

export default class NewPassword extends React.Component {

    state = {
        password: '',
        confPassword: '',
        messageText: '',
        messageType: '',
        messageHidden: true,
        messageState: {
            negative: false,
            positive: true
        }
    }

    componentDidMount() {
        const errorStyle = {className: 'error-style'};
        if (!localStorage.getItem('restoreToken')) {
            toastr.error('Error', 'There is no such page', errorStyle);
            this.props.history.push('/login')
        }
    }

    handleSubmit = async () => {
        if (this.state.password.length < 8 || this.state.confPassword.length < 8) {
            this.setState({ 
                messageHidden: false,
                messageType: 'Error',
                messageText: 'Password must be minimum 8 letters',
                messageState: {negative: true, positive: false},
            }) 
            return;
        } 
        const response = await Api.auth.changePass({token: localStorage.getItem('restoreToken'), password: this.state.password, confirmPassword: this.state.confPassword});
        if (response.status !== 200) {
            this.setState({ 
                messageHidden: false,
                messageType: 'Error',
                messageText: response.error.message,
                messageState: {negative: true, positive: false},
            }) 
        } else {
            this.setState({ 
                messageHidden: false,
                messageType: 'Succes',
                messageText: 'Your password was succesfully changed!',
                messageState: {negative: false, positive: true},
            })
            this.props.history.push('/login');
            localStorage.removeItem('restoreToken');
        }

    }

    handleInputChange = name => event => {
        this.setState({[name]: event.target.value, messageHidden: true,});
    }

    render () {
        return (
            <div className="new-password-page">
                <div className='new-password-block'>
                    <h3>Please, change your password</h3>
                    <div>
                        <div className='password-flex-item-1'>New Password</div>
                        <Input type='password' placeholder='new password' onChange={this.handleInputChange('password')}/>
                    </div>
                    <div>
                        <div className='password-flex-item-1'>Confirm</div>
                        <Input type='password' placeholder='repeat password' onChange={this.handleInputChange('confPassword')}/>
                    </div>     
                    <p>{this.state.error}</p>
                    <Button color='teal' onClick={() => this.handleSubmit()} >Submit</Button>
                </div>
                <Message
                    size='big'
                    hidden={this.state.messageHidden}
                    {...this.state.messageState}>
                    <Message.Header>{this.state.messageType}</Message.Header>
                    {this.state.messageText}
                </Message>
            </div>
        )
    }
}