import BaseApiService from './base';

export class SeriesApiServices extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  getFiles(sequenceId) {
    return this.request({
      method: 'get',
      url: `api-v2/files/sequence/${sequenceId}`,
    }, false);

  }
  
  getReconstructionSequence(id) {
    return this.request({
      method: 'get',
      url: `api-v2/sequence/reconstructionsByProtocol/${id}`,
    }, false);
  }
}
