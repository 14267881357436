import BaseApiService from './base';

export class AdminApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  login(data) {
    return this.request({
      method: 'post',
      url: '/login',
      data,
      app: 'admin'
    }, true, false);
  }
}
