import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateActiveSeries } from '../../../actions';
import { Modal, Button, Container } from 'semantic-ui-react';
import parameters from '../parameters';

class ThicknessParametersModal extends Component {
  state = { 
      detectorArray: '',
      sliceThickness: '',
      pitch: '',
      speed: ''
    };

  editedParams = [
      {
          title: 'Detector Rows',
          key: 'detectorArray'
      },
      {
          title: 'Helical Thickness (mm)',
          key: 'sliceThickness'
      },
      {
          title: 'Pitch',
          key: 'pitch'
      },
      {
          title: 'Speed (mm/rot)',
          key: 'speed'
      },
  ];

  onClose = () => {
    this.setState({ parameterValue: '' });
    this.props.onClose();
  };

  onSave = () => {
    this.editedParams.forEach(element => {
        this.props.scannerAction.updateActiveSeries(element.key, this.state[element.key]);
    });     

    this.onClose();
  };

  componentDidMount() {
    const { detectorArray, sliceThickness, pitch, speed } = this.props.scanner.activeSeries;

    this.setState({
        detectorArray, 
        sliceThickness, 
        pitch, 
        speed
    })
  }

  componentWillReceiveProps(nextProps) {
    const { detectorArray, sliceThickness, pitch, speed } = nextProps.scanner.activeSeries;

    this.setState({
        detectorArray, 
        sliceThickness, 
        pitch, 
        speed
    })
  }

  render() {
    const { isModalOpen } = this.props;

    return (
      <Modal 
        className="scan-param-modal-th thickness"        
        closeOnDimmerClick={false} 
        onClose={this.onClose}
        dimmer={'blurring'} 
        open={isModalOpen} 
        size="mini" 
        closeIcon
      >
        <Modal.Header>Edit Thickness/Speed settings</Modal.Header>
        <Modal.Content>
        {
            this.editedParams.map(param => (
                <Container key={`${param.key}${param.title}`}>
                    <div className="ctrls-label">
                        {param.altText ? param.altText : param.title}
                    </div>
                    <div className="tk-ctrls">
                        {
                            parameters.thickness[param.key].options.map(el => 
                                <Button 
                                    key={`tk-btn-${el.value}`}
                                    className={
                                        this.state[param.key] !== '' && this.state[param.key] == el.value
                                        ? "tk-ctrl-btn active" 
                                        : "tk-ctrl-btn"
                                    }
                                    onClick={() => this.setState({[param.key]: el.value})}
                                >{el.text}</Button>)
                        }
                    </div>
                </Container>
            ))
        }
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onClose} content="Cancel" />
          <Button content="Save" onClick={this.onSave} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ updateActiveSeries }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThicknessParametersModal);
