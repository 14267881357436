import React, { Component } from "react";
import { Header, Button, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { Api } from "../../services/api";
import { bindActionCreators } from "redux";
import ParameterModal from "./ParameterModal";
import ProtocolsModal from "../patientProtocols/ProtocolsModal";
import parameters from "./parameters";
import {
  endExam,
  setDefault,
  startScan,
  storeSeries,
  selectSeries,
  storeSeriesFiles,
  storeSeriesFilesBuff,
  selectPreviousSeries,
  editStartScan,
  showNotification,
  disablePlanslice,
  updateValue,
  setCurrentSeriesIndex,
} from "../../actions";
import "./style.css";
import Tooltip from "../tooltip/tooltip";
import raioactiveBtn from "../../images/SimRadiation.png";

const SCANNABLE_TYPE = "Scannable";

class ScannerParameters extends Component {
  state = {
    isParameterModalOpen: false,
    selectedParameter: {},
    protocols: [],
    isNotification: false,
    isStartLocation: false,
    isEndLocation: false,
  };

  onCloseNotification = () => {
    this.setState({
      isNotification: false,
    });
  };

  handleParameterClick = (parameterKey) => {
    const { activeSeries } = this.props.scanner;

    if (activeSeries.id)
      this.setState({
        isParameterModalOpen: true,
        selectedParameter: parameters[parameterKey],
      });
    else
      this.props.notificationAction.showNotification(
        "Please select protocol first!"
      );
  };

  handleClose = () => {
    this.setState({ isParameterModalOpen: false, isProtocolModalOpen: false });
  };

  clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  renderParameters = (parameterKey) => {
    const { field, text, disabled } = parameters[parameterKey];
    const {
      activeSeries,
      scan: { isRunning },
      customConfig,
      scannedSeriesIds,
      currentSeriesIndex,
      currentSeriesIndexDisplayed,
      series,
    } = this.props.scanner;

    const config = customConfig[`0`];

    const isHidden = config && config["hide"];
    const isModified = config && config["modified"];
    const isReset = config && config["reset"];
    const isLocalizer = activeSeries.scanType === "1";
    const isScanned = scannedSeriesIds[currentSeriesIndex] !== undefined;

    // set value to parameters object
    const coords = config && config["coords"];
    const sideL = 100;

    const isDisplayedLocalizer =
      currentSeriesIndexDisplayed === (series[0] && series[0]["id"]);

    parameters[parameterKey]["value"] = activeSeries[field];

    let label = "";

    if (parameters[parameterKey].options && field !== "gantryTilt") {
      label = parameters[parameterKey].options.find(
        (el) => el.value == activeSeries[field]
      );
      if (label) {
        label = label.text;
      }
    } else if (
      parameterKey !== "fieldOfView" &&
      parameterKey !== "startLocation" &&
      parameterKey !== "endLocation"
    ) {
      label = activeSeries[field];
    }

    if (parameterKey === "fieldOfView" && customConfig) {
      //show modified, if not exist show default
      const FoV = this.clamp(activeSeries["fieldOfView"], 24, 70);

      if (isLocalizer || isReset) {
        label = FoV.toString();
      } else if (!isLocalizer && !isDisplayedLocalizer && isScanned) {
        label = FoV.toString();
      } else if (
        (isLocalizer || isDisplayedLocalizer) &&
        (isModified || isScanned)
      ) {
        label = FoV.toString();
      } else {
        label = "‎";
      }
    }

    if (
      parameterKey === "startLocation" &&
      !this.props.scanner.isStartLocation
    ) {
      const start = coords
        ? Math.abs(Number(sideL - coords.mt.y).toFixed())
        : sideL;

      // console.log({ isLocalizer, isDisplayedLocalizer, isScanned });
      if ((isLocalizer && !isModified) || (isLocalizer && isReset)) {
        label = "‎";
      } else if (!isLocalizer && !isDisplayedLocalizer && isScanned) {
        label = start;
      } else if (
        (isLocalizer || isDisplayedLocalizer) &&
        (isModified || isScanned)
      ) {
        label = start;
      } else {
        label = "‎";
      }
    }

    if (parameterKey === "endLocation" && !this.props.scanner.isEndLocation) {
      const end = coords
        ? Math.abs(Number(sideL - coords.mb.y).toFixed())
        : sideL;

      if ((isLocalizer && !isModified) || (isLocalizer && isReset)) {
        label = "‎";
      } else if (!isLocalizer && !isDisplayedLocalizer && isScanned) {
        label = end;
      } else if (
        (isLocalizer || isDisplayedLocalizer) &&
        (isModified || isScanned)
      ) {
        label = end;
      } else {
        label = "‎";
      }
    }

    return (
      <Tooltip
        paramKey={field}
        key={parameterKey}
        trigger={
          <div className="scan-param-item">
            <Button
              fluid
              className="parameter-card-content"
              disabled={disabled || isRunning}
              onClick={() => this.handleParameterClick(parameterKey)}
            >
              {text}
            </Button>
            <div className="ui input scan-param" style={{ whiteSpace: "pre" }}>
              {label}
            </div>
          </div>
        }
      />
    );
  };

  getAllProtocols = async () => {
    const protocols = await Api.protocol.getAll();

    if (protocols.data && protocols.data.data.length) {
      const ptcls = protocols.data.data.sort((a, b) => {
        if (a.partOfBody < b.partOfBody) return -1;
        if (a.partOfBody > b.partOfBody) return 1;
        return 0;
      });

      this.setState({
        isProtocolModalOpen: true,
        protocols: ptcls,
      });
    } else {
      this.props.notificationAction.showNotification(
        "No protocols for the selected part of body!"
      );
    }
  };

  onProtocolClick = async (protocol) => {
    this.setState({ isProtocolModalOpen: false });
    const series = await Api.protocol.getSeries(protocol.id);
    const { data } = series.data;

    const preparedSequences = [];
    data.forEach((el) => {
      if (el.type === SCANNABLE_TYPE) {
        preparedSequences.push(el);
      }
    });

    if (preparedSequences && preparedSequences.length)
      this.props.scannerAction.storeSeries(protocol, preparedSequences);
    else
      this.props.notificationAction.showNotification(
        "No sequences for the selected protocol!"
      );
  };

  onScanStart = async () => {
    const { activeSeries } = this.props.scanner;
    this.props.planSlicesAction.disablePlanslice("autoView");
    if (activeSeries.id) {
      const response = await Api.series.getFiles(activeSeries.id);
      if (response.data && response.data.data) {
        this.props.scannerAction.storeSeriesFilesBuff({
          ...response.data.data,
          id: activeSeries.id,
        });
      }
      this.props.guideAction.editStartScan(true);
      this.props.scannerAction.startScan();
    } else {
      this.props.notificationAction.showNotification(
        "Please select protocol first!"
      );
    }

    this.props.scannerAction.updateValue("isStartLocation", false);
    this.props.scannerAction.updateValue("isEndLocation", false);
    // this.props.scannerAction.updateValue('currentAutoviewSlide', Infinity);
  };

  getImagesAfterChangeSeries = async (id) => {
    const response = await Api.series.getFiles(id);

    if (response.data && response.data.data) {
      this.props.scannerAction.storeSeriesFiles(response.data.data);
    }

    return;
  };

  handleChange = async (newSerieIndex) => {
    const { currentSeriesIndex, autoViewSeriesIds, series } =
      this.props.scanner;

    const id = series[newSerieIndex]["id"];
    const index = autoViewSeriesIds.findIndex((item) => item === id);

    // console.log({ id, index });
    if (index > -1) {
      this.props.scannerAction.setCurrentSeriesIndex(id);

      await this.getImagesAfterChangeSeries(id);
      this.props.scannerAction.updateValue("currentAutoviewSlide", 0);
      this.props.scannerAction.updateValue("keyIndex", parseInt(index));
    }
  };

  render() {
    const {
      hasNextSeries,
      hasPreviousSeries,
      currentSeriesIndex,
      scannedSeriesIds,
      series,
      scan: { isRunning },
    } = this.props.scanner;
    const { selectPreviousSeries, selectSeries, endExam } =
      this.props.scannerAction;

    return (
      <div id="scan_params">
        <Header as="h5" textAlign="left" content="SCANNER PARAMETERS" />
        <div className="scan-params-btns">
          {Object.keys(parameters).map((parameterKey) =>
            this.renderParameters(parameterKey)
          )}
        </div>
        <br />
        <br />
        <div className="scan-params-ctrls">
          <Button
            content="End Exam"
            className="parameter-ctrl min-btn"
            onClick={() => {
              endExam();
              if (this.props.planSlices.canvas["autoView"])
                this.props.planSlices.canvas["autoView"].clear();
              if (this.props.planSlices.canvas["reconstruction"])
                this.props.planSlices.canvas["reconstruction"].clear();
            }}
            disabled={isRunning}
          />
          <Button
            disabled={isRunning}
            className="parameter-ctrl new-ptcl min-btn"
            content="Select New Protocol"
            onClick={this.getAllProtocols}
          />
          <Button
            className="parameter-ctrl prev-series min-btn"
            content="Previous Series"
            disabled={!hasPreviousSeries || isRunning}
            onClick={async () => {
              selectSeries(currentSeriesIndex - 1);

              this.props.scannerAction.updateValue("isStartLocation", false);
              this.props.scannerAction.updateValue("isEndLocation", false);

              if (scannedSeriesIds.includes(currentSeriesIndex - 1)) {
                this.handleChange(currentSeriesIndex - 1);
                this.props.scannerAction.updateValue("currentAutoviewSlide", 0);
              }
            }}
          />
          <Button
            className="parameter-ctrl min-btn"
            content="Next Series"
            disabled={!hasNextSeries || isRunning}
            onClick={async () => {
              selectSeries(currentSeriesIndex + 1);
              this.props.scannerAction.updateValue("isStartLocation", false);
              this.props.scannerAction.updateValue("isEndLocation", false);

              if (scannedSeriesIds.includes(currentSeriesIndex + 1)) {
                await this.handleChange(currentSeriesIndex + 1);
                this.props.scannerAction.updateValue("currentAutoviewSlide", 0);
              }
            }}
          />
          {!isRunning && (
            <Button
              disabled={isRunning}
              className="min-btn"
              style={{
                width: "100px",
                height: "3.5rem",
                margin: "3px",
                marginLeft: "auto",
              }}
              content="Start Scan"
              onClick={() => this.onScanStart()}
            />
          )}
          {isRunning && (
            <Button
              color="yellow"
              className="min-btn"
              style={{
                width: "100px",
                height: "3.5rem",
                margin: "3px",
                marginLeft: "auto",
              }}
              disabled={isRunning}
              content={
                <Image
                  src={raioactiveBtn}
                  style={{ width: "25px", margin: "auto" }}
                />
              }
            />
          )}
        </div>
        <ParameterModal
          isModalOpen={this.state.isParameterModalOpen}
          onClose={this.handleClose}
          selectedParameter={this.state.selectedParameter}
        />
        <ProtocolsModal
          onClose={this.handleClose}
          modalOpen={this.state.isProtocolModalOpen}
          protocols={this.state.protocols}
          onProtocolClick={this.onProtocolClick}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = (dispatch) => ({
  scannerAction: bindActionCreators(
    {
      storeSeries,
      storeSeriesFiles,
      selectSeries,
      selectPreviousSeries,
      startScan,
      endExam,
      setDefault,
      storeSeriesFilesBuff,
      updateValue,
      setCurrentSeriesIndex,
    },
    dispatch
  ),
  guideAction: bindActionCreators(
    {
      editStartScan,
    },
    dispatch
  ),
  notificationAction: bindActionCreators(
    {
      showNotification,
    },
    dispatch
  ),
  planSlicesAction: bindActionCreators(
    {
      disablePlanslice,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScannerParameters);
