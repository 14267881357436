import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { prepateTime } from '../../helpers/timeFormat';
import './style.css';

class Syringe extends Component {
  state = {
    startFirstInjection: false,
    startSecondInjection: false,
    totalDuration: 0,
    firstIntervalId: null,
    timeoutId: null,
    volumeLeft: 0,
    firstPhaseDelayIntervalId: 0,
    firstPhaseDelayLeft: 0,
    secondPhaseDelayIntervalId: 0,
    secondPhaseDelayLeft: 0,
  };

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
    clearInterval(this.state.firstIntervalId);
    clearInterval(this.state.secondIntervalId);
  }

  componentWillReceiveProps(nextProps) {
    const { isRunning: currentPropIsRunning } = this.props.scan;
    const {
      scan: { isRunning: nextPropIsRunning, cancelled },
      pressureInjector: {
        firstPhaseDelay,
        secondPhaseDelay,
        firstPhaseDuration,
        firstPhaseVolume,
        secondPhaseVolume,
        secondPhaseDuration
      }
    } = nextProps;

    if (!currentPropIsRunning && nextPropIsRunning) {
      if (firstPhaseDuration > 0) {
        const firstPhaseDelayIntervalId = setInterval(this.firstInjectionDelay, 10)

        // store firstIntervalId
        const firstIntervalId = setInterval(this.firstInjectionDuration, 10);

        this.setState({
          firstIntervalId,
          firstPhaseTotalDuration: firstPhaseDuration,
          firstPhaseVolumeLeft: firstPhaseVolume,
          firstPhaseDelayIntervalId,
          firstPhaseDelayLeft: firstPhaseDelay
        });
        // start first injection
        this.startFirstInjection();
      }
      else if (secondPhaseDuration > 0) {
        const secondPhaseDelayIntervalId = setInterval(this.secondInjectionDelay, 10)
        // store secondIntervalId
        const secondIntervalId = setInterval(this.secondInjectionDuration, 10);

        this.setState({
          secondIntervalId,
          secondPhaseTotalDuration: secondPhaseDuration,
          secondPhaseVolumeLeft: secondPhaseVolume,
          secondPhaseDelayIntervalId,
          secondPhaseDelayLeft: secondPhaseDelay
        });
        // start second injection
        this.startSecondInjection();
      }
    }

    // check if scan is cancelled
    if (currentPropIsRunning && cancelled) {
      // stop injections
      this.setState({
        startFirstInjection: false,
        startSecondInjection: false,
        totalDuration: 0,
        volumeLeft: 0,
        firstPhaseDelayLeft: 0,
        secondPhaseDelayLeft: 0
      });
      // clear timeout and interval
      clearTimeout(this.state.timeoutId);
      clearInterval(this.state.firstIntervalId);
      clearInterval(this.state.secondIntervalId);
    }
  }

  firstInjectionDelay = () => {
    const { firstPhaseDelayLeft } = this.state;

    if (firstPhaseDelayLeft > 0) {
      this.setState({
        firstPhaseDelayLeft: (firstPhaseDelayLeft - 0.01).toFixed(2),
      });
    } else {
      this.setState({ firstPhaseDelayLeft: 0 });
      clearInterval(this.state.firstPhaseDelayIntervalId);
    }
  }

  secondInjectionDelay = () => {
    const { secondPhaseDelayLeft } = this.state;

    if (secondPhaseDelayLeft > 0) {
      this.setState({
        secondPhaseDelayLeft: (secondPhaseDelayLeft - 0.01).toFixed(2),
      });
    } else {
      this.setState({ secondPhaseDelayLeft: 0 });
      clearInterval(this.state.secondPhaseDelayIntervalId);
    }
  }

  startFirstInjection = () => {
    const {
      firstPhaseDuration,
      secondPhaseDuration,
      firstPhaseDelay
    } = this.props.pressureInjector;

    // // store timeoutId in the state so it can be accessed later
    const timeoutId = setTimeout(() => {
      this.setState({ startFirstInjection: false }, () => {
        if (secondPhaseDuration > 0) {
          const { secondPhaseDuration, secondPhaseVolume, secondPhaseDelay } = this.props.pressureInjector;
          const secondPhaseDelayIntervalId = setInterval(this.secondInjectionDelay, 10)

          const secondIntervalId = setInterval(this.secondInjectionDuration, 10);

          this.setState({
            secondPhaseTotalDuration: secondPhaseDuration,
            secondPhaseVolumeLeft: secondPhaseVolume,
            secondPhaseDelayIntervalId,
            secondPhaseDelayLeft: secondPhaseDelay,
            secondIntervalId
          }, () => {
            this.startSecondInjection();
          });
        }
      });
    }, firstPhaseDuration * 1000 + firstPhaseDelay * 1000);
    this.setState({ startFirstInjection: true, timeoutId });
  };

  startSecondInjection = () => {
    const { secondPhaseDuration, secondPhaseDelay } = this.props.pressureInjector;

    // // store timeoutId in the state so it can be accessed later
    const timeoutId = setTimeout(() => {
      this.setState({ startSecondInjection: false });
    }, secondPhaseDuration * 1000 + secondPhaseDelay * 1000);

    // clear previus timeout
    clearTimeout(this.state.timeoutId);

    // set current timeout
    this.setState({
      secondPhaseDuration,
      startSecondInjection: true,
      timeoutId
    });
  };

  firstInjectionDuration = () => {
    const { firstPhaseTotalDuration, firstPhaseVolumeLeft, firstPhaseDelayLeft } = this.state;
    const { firstPhaseFlow } = this.props.pressureInjector;

    if (firstPhaseTotalDuration > 0) {
      if (firstPhaseDelayLeft <= 0)
        this.setState({
          firstPhaseTotalDuration: (firstPhaseTotalDuration - 0.01).toFixed(2),
          // interval is every 10 miliseconds so devide flow by 100 in order to get current flow
          firstPhaseVolumeLeft: (
            firstPhaseVolumeLeft -
            firstPhaseFlow / 100
          ).toFixed(2)
        });
    } else {
      this.setState({ firstPhaseVolumeLeft: 0 });
      clearInterval(this.state.firstIntervalId);
    }
  };

  secondInjectionDuration = () => {
    const { secondPhaseTotalDuration, secondPhaseVolumeLeft, secondPhaseDelayLeft } = this.state;
    const { secondPhaseFlow } = this.props.pressureInjector;

    if (secondPhaseTotalDuration > 0) {
      if (secondPhaseDelayLeft <= 0)
        this.setState({
          secondPhaseTotalDuration: (secondPhaseTotalDuration - 0.01).toFixed(2),
          // interval is every 10 miliseconds, so devide flow by 100
          secondPhaseVolumeLeft: (
            secondPhaseVolumeLeft -
            secondPhaseFlow / 100
          ).toFixed(2)
        });
    } else {
      this.setState({ secondPhaseVolumeLeft: 0 });
      clearInterval(this.state.secondIntervalId);
      this.setState({ startSecondInjection: false })
    }
  };

  syringeAnimation = (key, duration, volumeLeft, totalDuration, injectorDelay) => {
    const syringeLeft = Number(100 - ((totalDuration) * 100) / duration).toFixed(1);
    injectorDelay = prepateTime(Number(injectorDelay).toFixed(1));
    totalDuration = prepateTime(Number(totalDuration).toFixed(1));
    volumeLeft = prepateTime(Number(volumeLeft).toFixed(1));
    let info = '';
    if (injectorDelay > 0) {
      info = <h6 className={this.props.scan.isRunning ? '' : 'marginTop'}>Injector Delay: {injectorDelay} sec</h6>
    } else {
      info = <div className={this.props.scan.isRunning ? '' : 'marginTop'}>
        <h6>Injector time left: {totalDuration} sec</h6>
        <h6>Volume left: {volumeLeft} ml</h6>
      </div>
    }
    return (
      <Grid className="syringe-grid">
        <Grid.Column width={5} className="syringe-times">
          {info}
        </Grid.Column>
        <Grid.Column width={9} className="syringe-column">
          <label className="syringe-label-side" >{
            this.state.startFirstInjection ?
              (this.props.pressureInjector.firstPhaseSide === 'a' ?
                'A' :
                (this.props.pressureInjector.firstPhaseSide === 'b' ?
                  'B' : '')) :
              (this.props.pressureInjector.secondPhaseSide === 'a' ?
                'A' :
                (this.props.pressureInjector.secondPhaseSide === 'b' ? 'B' : ''))
          }
          </label>
          <div className="syringe-container" key={key}>
            {Number(injectorDelay) <= 0 && <div className="syringe">
              <div
                className="syringe-inside"
                style={{
                  animation: `move-syringe-inside linear ${duration}s`
                }}
              ><span className="syringe-indicator">{syringeLeft}%</span></div>
              <div
                className="blood"
                style={{ animation: `move-blood linear ${duration}s` }}
              />
            </div>}
            {Number(injectorDelay) > 0 && <div className="syringe">
              <div
                className="syringe-inside"
                style={{
                  animation: `move-syringe-inside linear 0s`
                }}
              ><span className="syringe-indicator">{syringeLeft}%</span></div>
              <div
                className="blood"
                style={{ animation: `move-blood linear 0s` }}
              />
            </div>}
            <div className="syringe-lines">
              <div className="long-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="long-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="long-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
              <div className="short-syringe-line syringe-line" />
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  };

  render() {
    const {
      firstPhaseDuration,
      secondPhaseDuration
    } = this.props.pressureInjector;

    const {
      startFirstInjection,
      startSecondInjection,
      firstPhaseTotalDuration,
      firstPhaseVolumeLeft,
      secondPhaseTotalDuration,
      secondPhaseVolumeLeft,
      firstPhaseDelayLeft,
      secondPhaseDelayLeft
    } = this.state;

    if (!this.props.scan.isRunning)
      return null;

    if (startFirstInjection) {
      return this.syringeAnimation(
        1,
        firstPhaseDuration,
        firstPhaseVolumeLeft,
        firstPhaseTotalDuration,
        firstPhaseDelayLeft
      );
    }


    if (startSecondInjection) {
      return this.syringeAnimation(
        2,
        secondPhaseDuration,
        secondPhaseVolumeLeft,
        secondPhaseTotalDuration,
        secondPhaseDelayLeft
      );
    }

    return (
      <div>
        <h6>Total injector time: 00:0 sec</h6>
      </div>
    );
  }
}

const mapStateToProps = ({ scanner: { pressureInjector, scan, isRunning } }) => ({
  pressureInjector,
  scan,
  isRunning,
});

export default connect(mapStateToProps)(Syringe);
