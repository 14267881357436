import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Modal, Button, Input } from 'semantic-ui-react';
import { Api } from '../../../services/api';
import NotificatioModal from '../../notificationModal';
import { showNotification } from '../../../actions';
import { config } from '../../../config';

import './style.css';

class AdminLoginModal extends React.Component {

    state = { 
        username: config.defaultAdminUser,
        password: '',
        goToAminApp: false,
        error: false,
    };

    handleChange = (e, { value }) => this.setState({ password: value, error: false  });

    handleSubmit = async () => {
        const response = await Api.admin.login({ 
            username: this.state.username,
            password: this.state.password,
        });

        if(response.status === 200) {
            window.localStorage.setItem('adminAuthToken', response.data.token);
            this.setState({ goToAminApp: true })
        } else {
            this.setState({ 
                error: true,
            });
            this.props.notificationAction.showNotification('Error occured while sending data!')
        }
    }

    render() {
        if(this.state.goToAminApp)
            return <Redirect to="/admin-app" />

        return (
            <React.Fragment>
                <Modal 
                    closeIcon
                    size="small" 
                    dimmer={'blurring'}
                    className="admin-modal"
                    open={this.props.isOpened} 
                    closeOnDimmerClick={false} 
                    onClose={this.props.onClose}                  
                >
                    <Modal.Header>Administrator password panel</Modal.Header>
                    <Modal.Content>
                        <label className="admin-label">To log the Administrator Panel, enter the password.</label>
                        <div className="admin-input-field">
                            <span>Password:</span>
                            <Input
                                fluid
                                type="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                            </div>
                    <div className="admin-content-action">
                        {this.state.error && <span className="error" >Incorrect password!</span>}
                        <Button 
                            className="login-btn" 
                            content="Login"
                            onClick={this.handleSubmit} />
                    </div>   
                    </Modal.Content>
                    <Modal.Actions></Modal.Actions>
                </Modal>
                <NotificatioModal 
                    isOpened={this.state.isModalOpened} 
                    onClose={() => this.setState({ isModalOpened: true })} 
                />
            </React.Fragment>      
        );
    }
}

const mapDispatchToProps = dispatch => ({
    notificationAction: bindActionCreators({ showNotification }, dispatch)
});
  
export default connect(
    null,
    mapDispatchToProps
)(AdminLoginModal);