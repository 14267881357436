import axios from 'axios';
import { config } from '../../config';
import { store } from '../../../index';
import { startLoading, stopLoading } from '../../actions'

export default class BaseApiService {
  handleResponse = response => {
    // stop loading
    store.dispatch(stopLoading())

    return response;
  };

  handleError = error => {
    // stop loading
    store.dispatch(stopLoading())

    if (error.response)
      return {
        error: error.response.data.message || error.response.data.error
      };
    else return { error: error };
  };

  request = ({ method, url, params, data, app, cookies }, enableLoader = true, addAuthToken = true) => {
    let headers = {
      app: 'ct'
    };

    if(app) headers.app = app;

    if(addAuthToken)
      headers = { 
        ...headers,
        Authorization: `Bearer ${window.localStorage.authToken}`
      };

    // start loading
    if(enableLoader)
      store.dispatch(startLoading())

    return axios({
      baseURL: config.apiUrl,
      timeout: 4000,
      maxContentLength: 5000,
      method,
      url,
      headers,
      params,
      data,
      cookies
    }).then(this.handleResponse, this.handleError);
  };
}
