import { handleActions } from 'redux-actions';
import {
    CANVAS_UPDATE_AUTOVIEW,
    CANVAS_UPDATE_RECONSTRUCTION,
    ENABLE_PLANSLICE,
    DISABLE_PLANSLICE,
    SET_DEFAULT
} from '../actions';

const defaultState = {
    canvas: {
        autoView: null,
        reconstruction: null
    },
    planeSliceEnable: {
        autoView: false,
        reconstruction: false
    }
}

export default handleActions(
    {
        [CANVAS_UPDATE_AUTOVIEW]: (state, { payload: { canvas } }) => {
            const updatedCanvas = {
                ...state.canvas,
                autoView: canvas
            }

            return {
                ...state,
                canvas: updatedCanvas
            }
        },
        [CANVAS_UPDATE_RECONSTRUCTION]: (state, { payload: { canvas } }) => {
            const updatedCanvas = {
                ...state.canvas,
                reconstruction: canvas
            }
            return {
                ...state,
                canvas: updatedCanvas
            }
        },
        [ENABLE_PLANSLICE]: (state, { payload: { id } }) => {
            const updatedCanvas = {
                ...state.planeSliceEnable,
                [id]: true
            }

            return {
                ...state,
                planeSliceEnable: updatedCanvas
            }
        },
        [DISABLE_PLANSLICE]: (state, { payload: { id } }) => {
            const updatedCanvas = {
                ...state.planeSliceEnable,
                [id]: false
            }

            return {
                ...state,
                planeSliceEnable: updatedCanvas
            }
        },
        [SET_DEFAULT]: state => {
            // return {
            //     ...state,
            //     canvas: {
            //         autoView: null,
            //         reconstruction: null
            //     },
            //     planeSliceEnable: {
            //         autoView: false,
            //         reconstruction: false
            //     }
            // }
        }
    },
    defaultState
);