import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from './userReducer';
import loadingReducer from './loadingReducer';
import scannerReducer from './scannerReducer';
import tabsReducer from './tabsReducer';
import planSlicesReducer from './planSlicesReducer';
import tooltipsReducer from './tooltipsReducer';
import stepsReducer from './stepsReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
  tabs: tabsReducer,
  user: userReducer,
  form: formReducer,
  toastr: toastrReducer,
  loading: loadingReducer,
  scanner: scannerReducer,
  planSlices: planSlicesReducer,
  tooltips: tooltipsReducer,
  steps: stepsReducer,
  notification: notificationReducer,
});
