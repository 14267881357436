import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { changeActiveTab, showNotification, updateValue } from '../../actions';
import ThicknessValueChange from './modals/ThicknessValueChange';
import InjectorParameterModal from './modals/InjectorParameterModal';
import ThicknessParametersModal from './modals/ThicknessParameters';
import parameters from './parameters';
import Tooltip from '../tooltip/tooltip';

class ThicknessTab extends Component {
  state = { 
    param: {key : ''},
    isModalOpen: false,    
    isThicknessModalOpen: false, 
    selectedParameter: {}, 
    isInjectorModalOpen: false ,
    isModalRecOpen: false
  };

  handleParameterClick = parameterKey => {
    const { activeSeries } = this.props.scanner;

    if (activeSeries.id)
      this.setState({
        param: {
          key: parameterKey
        },
        isModalOpen: true,
        selectedParameter: parameters.thickness[parameterKey]
      });
    else
      this.props.notificationAction.showNotification('Please select protocol first!')
  };
  
  handleInjectorParameterClick = parameterKey => {
    const { activeSeries } = this.props.scanner;

    if (activeSeries.id)
      this.setState({
        isInjectorModalOpen: true,
        selectedParameter: parameters.injector[parameterKey]
      });
    else
      this.props.notificationAction.showNotification('Please select protocol first!')
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleThicknessClose = () => {
    this.setState({ isThicknessModalOpen: false });
  };
  
  handleInjectorClose = (changeTab = false) => {
    this.setState({ isInjectorModalOpen: false });
    if(changeTab)
      this.props.tabsAction.changeActiveTab(2)
  };

  renderParameters = parameterKey => {
    const { key, field, text } = parameters.thickness[parameterKey];
    const {
      activeSeries,
      scan: { isRunning }
    } = this.props.scanner;
    // set value to parameters object
    parameters.thickness[parameterKey]['value'] = activeSeries[field];

    return (
      <Tooltip paramKey={field} key={key} trigger={<div className="scan-param-item">
          <Button
            fluid
            className="parameter-card-content min-btn"
            disabled={isRunning || !activeSeries.id}
            onClick={() => this.handleParameterClick(parameterKey)}
          >
            {text}
          </Button>
          <div className="ui input scan-param">
            {activeSeries[field]}
          </div>
      </div>} />
    );
  };

  render() {
    const { activeSeries, scan:{isRunning }} = this.props.scanner;

    return (
      <div>
      <div className="thickness-params-btns">
          {Object.keys(parameters.thickness).map(parameterKey => {
            return this.renderParameters(parameterKey);
          })}
        </div>
        <ThicknessValueChange
          isModalOpen={this.state.isModalOpen}
          onClose={this.handleClose}
          selectedParameter={this.state.selectedParameter}          
          param={this.state.param}
        />
        <InjectorParameterModal
          isModalOpen={this.state.isInjectorModalOpen}
          onClose={this.handleInjectorClose}
          selectedParameter={this.state.selectedParameter}
        />
        <ThicknessParametersModal
          isModalOpen={this.state.isThicknessModalOpen}
          onClose={this.handleThicknessClose}
        />
        <div className="change-tk-values">
          <Button 
            className="tk-btn min-btn" 
            disabled={isRunning || (activeSeries.id? false: true)}
            onClick={() => this.setState({isThicknessModalOpen: true})}
          >Change Values</Button>
        </div>
        <div className="contrast-oral-item">          
          <Button
            disabled={isRunning}
            className="th-parameter-ctrl min-btn padding-top"
            content="Pressure Injector"
            onClick={() => this.props.tabsAction.changeActiveTab(2)}
          />
          <Button
            fluid
            className="th-parameter-ctrl min-btn padding-top"
            disabled={isRunning}
            onClick={() => this.handleInjectorParameterClick('contrastOral')}
          >
            Oral Contrast
          </Button>
          <Button
            fluid
            className="th-parameter-ctrl min-btn reconstruction padding-top"
            disabled={isRunning 
              || this.props.scanner.scannedSeriesIds.length === 0}
            onClick={() => {
              this.props.scanActions.updateValue('isModalRecOpen', true);
            }}
          >
            Reconstructions
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = dispatch => ({
  tabsAction: bindActionCreators({ changeActiveTab }, dispatch),
  notificationAction: bindActionCreators({ showNotification }, dispatch),
  scanActions: bindActionCreators({ updateValue }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThicknessTab);
