import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table, Input, Header, Grid, Form, Button } from 'semantic-ui-react';
import { updatePressureInjector, showNotification } from '../../actions';
import ParameterModal from './modals/InjectorParameterModal';
import parameters from './parameters';

class InjectorTab extends Component {
  state = { isModalOpen: false, selectedParameter: {} };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleParameterClick = parameterKey => {
    const { activeSeries, pressureInjector } = this.props.scanner;

    if (activeSeries.id) {
      if (parameterKey !== 'firstPhaseSide' && parameterKey !== 'secondPhaseSide') {
        if (parameterKey.includes('first')) {
          if (pressureInjector.firstPhaseSide === undefined) {
            this.props.notificationAction.showNotification('Please select phase first!')

            return;
          }
        } else {
          if (pressureInjector.secondPhaseSide === undefined) {
            this.props.notificationAction.showNotification('Please select phase first!')

            return;
          }
        }
      }
      console.log(pressureInjector[parameterKey])
      parameters.injector[parameterKey].value = pressureInjector[parameterKey];
      this.setState({
        isModalOpen: true,
        selectedParameter: parameters.injector[parameterKey]
      });
    } else {
      this.props.notificationAction.showNotification('Please select protocol first!')
    }
  };

  componentWillReceiveProps(nextProps) {
    const newPressureInjector = nextProps.scanner.pressureInjector;
    const {
      firstPhaseFlow,
      firstPhaseVolume,
      secondPhaseFlow,
      secondPhaseVolume,
      firstPhaseSide,
      secondPhaseSide,
    } = this.props.scanner.pressureInjector;

    if (
      (!isNaN(newPressureInjector.firstPhaseFlow) && newPressureInjector.firstPhaseFlow !== firstPhaseFlow) ||
      (!isNaN(newPressureInjector.firstPhaseVolume) && newPressureInjector.firstPhaseVolume !== firstPhaseVolume)
    ) {
      const firstPhaseDurationCalc = this.calculateDuration(
        newPressureInjector.firstPhaseVolume,
        newPressureInjector.firstPhaseFlow
      );

      this.props.scannerAction.updatePressureInjector(
        'firstPhaseDuration',
        firstPhaseDurationCalc
      );
    }

    if (
      (!isNaN(newPressureInjector.secondPhaseFlow) && newPressureInjector.secondPhaseFlow !== secondPhaseFlow) ||
      (!isNaN(newPressureInjector.secondPhaseVolume) && newPressureInjector.secondPhaseVolume !== secondPhaseVolume)
    ) {
      const secondPhaseDurationCalc = this.calculateDuration(
        newPressureInjector.secondPhaseVolume,
        newPressureInjector.secondPhaseFlow
      );

      this.props.scannerAction.updatePressureInjector(
        'secondPhaseDuration',
        secondPhaseDurationCalc
      );
    }

    if (newPressureInjector.firstPhaseSide === 'no') {
      const paramsPhase1 = ['firstPhaseFlow', 'firstPhaseVolume', 'firstPhaseDelay', 'firstPhaseDuration'];
      paramsPhase1.forEach(p => {
        this.props.scannerAction.updatePressureInjector(
          p, 0
        );
      })
      this.props.scannerAction.updatePressureInjector('firstPhaseSide', '');
    }

    if (newPressureInjector.secondPhaseSide === 'no') {
      const paramsPhase2 = ['secondPhaseFlow', 'secondPhaseVolume', 'secondPhaseDelay', 'secondPhaseDuration'];
      paramsPhase2.forEach(p => {
        this.props.scannerAction.updatePressureInjector(
          p, 0
        );
      })
      this.props.scannerAction.updatePressureInjector('secondPhaseSide', '');
    }

  }

  calculateDuration = (phaseVolume, phaseFlow) => {
    return phaseVolume && phaseFlow ? Math.floor(phaseVolume / phaseFlow) : 0;
  };

  calculateTotalTime = () => {
    const {
      firstPhaseDuration,
      secondPhaseDuration
    } = this.props.scanner.pressureInjector;
    let seconds = parseInt(firstPhaseDuration) || 0 + parseInt(secondPhaseDuration) || 0;

    let minutes = Math.floor(seconds / 60);
    minutes >= 1 ? (seconds = seconds - minutes * 60) : (minutes = '0');
    seconds < 1 ? (seconds = '0') : void 0;

    return `Total time: ${minutes}:${seconds}`;
  };

  getPhaseSide = side => {
    const {
      firstPhaseSide,
      secondPhaseSide
    } = this.props.scanner.pressureInjector;

    return firstPhaseSide === side || secondPhaseSide === side ? 'Yes' : 'No';
  };

  componentDidMount() {
    const {
      firstPhaseFlow,
      firstPhaseVolume,
      secondPhaseFlow,
      secondPhaseVolume,
    } = this.props.scanner.pressureInjector;

    const firstPhaseDurationCalc = this.calculateDuration(
      firstPhaseVolume,
      firstPhaseFlow
    );

    this.props.scannerAction.updatePressureInjector(
      'firstPhaseDuration',
      firstPhaseDurationCalc
    );

    const secondPhaseDurationCalc = this.calculateDuration(
      secondPhaseVolume,
      secondPhaseFlow
    );

    this.props.scannerAction.updatePressureInjector(
      'secondPhaseDuration',
      secondPhaseDurationCalc
    );
  }

  render() {
    const {
      scanner: {
        pressureInjector,
        scan: { isRunning }
      }
    } = this.props;

    return (
      <div id="injector">
        <h3 className="ui header">
          <b>A</b>:&nbsp;&nbsp;&nbsp;&nbsp;{this.getPhaseSide('a')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>B</b>:&nbsp;&nbsp;&nbsp;&nbsp;{this.getPhaseSide('b')}</h3>
        <Table className="injector-table" textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Phase</Table.HeaderCell>
              <Table.HeaderCell>Side</Table.HeaderCell>
              <Table.HeaderCell>Flow ml/sec</Table.HeaderCell>
              <Table.HeaderCell>Volume ml</Table.HeaderCell>
              <Table.HeaderCell>Duration sec</Table.HeaderCell>
              <Table.HeaderCell>Delay sec</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell className="t-title" width={1}>PH1:</Table.Cell>
              <Table.Cell className="injector-input" width={2}>
                <Input
                  fluid
                  name="firstPhaseSide"
                  className="text-align-center"
                  readOnly
                  value={pressureInjector.firstPhaseSide !== undefined ? pressureInjector.firstPhaseSide.toUpperCase() : '-'}
                  onClick={() => this.handleParameterClick('firstPhaseSide')}
                />
              </Table.Cell>
              <Table.Cell className="injector-input" width={2}>
                <Input
                  fluid
                  type="number"
                  name="firstPhaseFlow"
                  className="text-align-right"
                  readOnly
                  value={pressureInjector.firstPhaseFlow || 0}
                  onClick={() => this.handleParameterClick('firstPhaseFlow')}
                />
              </Table.Cell>
              <Table.Cell className="injector-input" width={2}>
                <Input
                  fluid
                  type="number"
                  name="firstPhaseVolume"
                  className="text-align-right"
                  readOnly
                  value={pressureInjector.firstPhaseVolume ? pressureInjector.firstPhaseVolume : 0}
                  onClick={() => this.handleParameterClick('firstPhaseVolume')}
                />
              </Table.Cell>
              <Table.Cell className="injector-input" width={2}>
                {pressureInjector.firstPhaseDuration ? pressureInjector.firstPhaseDuration : '-'}
              </Table.Cell>
              <Table.Cell className="injector-input" width={2}>
                <Input
                  fluid
                  type="number"
                  name="firstPhaseDelay"
                  className="text-align-center"
                  readOnly
                  value={pressureInjector.firstPhaseDelay ? pressureInjector.firstPhaseDelay : 0}
                  onClick={() => this.handleParameterClick('firstPhaseDelay')}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="t-title">PH2:</Table.Cell>
              <Table.Cell className="injector-input">
                <Input
                  fluid
                  name="secondPhaseSide"
                  className="text-align-center"
                  readOnly
                  value={pressureInjector.secondPhaseSide !== undefined ? pressureInjector.secondPhaseSide.toUpperCase() : '-'}
                  onClick={() => this.handleParameterClick('secondPhaseSide')}
                />
              </Table.Cell>
              <Table.Cell className="injector-input">
                <Input
                  fluid
                  type="number"
                  name="secondPhaseFlow"
                  className="text-align-right"
                  readOnly
                  value={pressureInjector.secondPhaseFlow ? pressureInjector.secondPhaseFlow : 0}
                  onClick={() => this.handleParameterClick('secondPhaseFlow')}
                />
              </Table.Cell>
              <Table.Cell className="injector-input">
                <Input
                  fluid
                  type="number"
                  name="secondPhaseVolume"
                  className="text-align-right"
                  readOnly
                  value={pressureInjector.secondPhaseVolume ? pressureInjector.secondPhaseVolume : 0}
                  onClick={() => this.handleParameterClick('secondPhaseVolume')}
                />
              </Table.Cell>
              <Table.Cell className="injector-input">
                {pressureInjector.secondPhaseDuration ? pressureInjector.secondPhaseDuration : '-'}
              </Table.Cell>
              <Table.Cell className="injector-input">
                <Input
                  fluid
                  type="number"
                  name="secondPhaseDelay"
                  className="text-align-center"
                  readOnly
                  value={pressureInjector.secondPhaseDelay ? pressureInjector.secondPhaseDelay : 0}
                  onClick={() => this.handleParameterClick('secondPhaseDelay')}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header as="h4" textAlign="center">
          {this.calculateTotalTime()}
        </Header>
        <Grid>
          <Grid.Column width={8}>
            <div className="contrast-oral-items margin-top">
              <Button
                fluid
                className="contrast-oral-btn min-btn"
                disabled={isRunning}
                onClick={() => this.handleParameterClick('contrastOral')}
              >
                Oral Contrast
                </Button>
              <div className="contrast-oral-data">
                <span>{pressureInjector.contrastOral}</span>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Input
              fluid
              type="number"
              name="scanDelay"
              label="Scan Delay"
              className="scan-delay"
              placeholder=" Delay"
              readOnly
              value={pressureInjector.scanDelay}
              onClick={() => this.handleParameterClick('scanDelay')}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Input
              fluid
              type="number"
              name="peakPsi"
              label="Peak PSI"
              placeholder="PSI"
              className="peak-psi"
              readOnly
              value={pressureInjector.peakPsi}
              onClick={() => this.handleParameterClick('peakPsi')}
            />
          </Grid.Column>
        </Grid>
        <ParameterModal
          isModalOpen={this.state.isModalOpen}
          onClose={this.handleClose}
          selectedParameter={this.state.selectedParameter}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ updatePressureInjector }, dispatch),
  notificationAction: bindActionCreators({ showNotification }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectorTab);
