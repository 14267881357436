export default {
  thickness: {
    detectorArray: {
      key: 'th-1',
      text: 'Detector Array',
      altText: 'Detector Rows',
      field: 'detectorArray',
      type: 'dropdown',
      options: [
        { key: 1, text: 2, value: '2' },
        { key: 2, text: 4, value: '4' },
        { key: 3, text: 8, value: '8' },
        { key: 4, text: 16, value: '16' },
        { key: 5, text: 32, value: '32' },
        { key: 6, text: 64, value: '64' },
        { key: 7, text: 128, value: '128' },
        { key: 8, text: 256, value: '256' },
      ],
    },
    sliceThickness: {
      key: 'th-2',
      text: 'Slice Thickness',
      altText: 'Helical Thickness (mm)',
      field: 'sliceThickness',
      type: 'dropdown',
      options: [
        { key: 1, text: '0.625', value: '0.625' },
        { key: 2, text: '1.25', value: '1.25' },
        { key: 3, text: '2.5', value: '2.5' },
        { key: 4, text: '3.75', value: '3.75' },
        { key: 5, text: '5', value: '5' },
        { key: 6, text: '7.5', value: '7.5' },
        { key: 7, text: '10', value: '10' },
      ],
    },
    pitch: {
      key: 'th-3',
      text: 'Pitch',
      field: 'pitch',
      type: 'dropdown',
      options: [
        { key: 1, text: '0.625:1', value: '0.625:1' },
        { key: 2, text: '0.875:1', value: '0.875:1' },
        { key: 3, text: '1.35:1', value: '1.35:1' },
        { key: 4, text: '1.675:1', value: '1.675:1' },
      ],
    },
    speed: {
      key: 'th-4',
      text: 'Speed',
      altText: 'Speed (mm/rot)',
      field: 'speed',
      type: 'dropdown',
      options: [
        { key: 1, text: '6.25', value: '6.25' },
        { key: 2, text: '8.25', value: '8.25' },
        { key: 3, text: '12.50', value: '12.50' },
        { key: 4, text: '13.50', value: '13.50' },
        { key: 5, text: '16.75', value: '16.75' },
        { key: 6, text: '17.50', value: '17.50' },
        { key: 7, text: '27.00', value: '27.00' },
        { key: 8, text: '33.50', value: '33.50' },
      ],
    },
    sliceGap: {
      key: 'th-5',
      text: 'Slice Gap',
      field: 'sliceGap',
      type: 'number',
    },
  },
  injector: {
    firstPhaseSide: {
      text: 'First Phase Side',
      field: 'firstPhaseSide',
      type: 'dropdown',
      options: [
        { key: 0, text: 'No', value: 'no' },
        { key: 1, text: 'A', value: 'a' },
        { key: 2, text: 'B', value: 'b' },
      ],
    },
    secondPhaseSide: {
      text: 'Second Phase Side',
      field: 'secondPhaseSide',
      type: 'dropdown',
      options: [
        { key: 0, text: 'No', value: 'no' },
        { key: 1, text: 'A', value: 'a' },
        { key: 2, text: 'B', value: 'b' },
      ],
    },
    contrastOral: {
      field: 'contrastOral',
      text: 'Oral Contrast',
      type: 'text',
    },
    firstPhaseFlow: {
      field: 'firstPhaseFlow',
      text: 'First Phase Flow',
      type: 'number',
    },
    firstPhaseVolume: {
      field: 'firstPhaseVolume',
      text: 'First Phase Volume',
      type: 'number',
    },
    firstPhaseDelay: {
      field: 'firstPhaseDelay',
      text: 'First Phase Delay',
      type: 'number',
    },
    secondPhaseFlow: {
      field: 'secondPhaseFlow',
      text: 'Second Phase Flow',
      type: 'number',
    },
    secondPhaseVolume: {
      field: 'secondPhaseVolume',
      text: 'Second Phase Volume',
      type: 'number',
    },
    secondPhaseDelay: {
      field: 'secondPhaseDelay',
      text: 'Second Phase Delay',
      type: 'number',
    },
    scanDelay: { field: 'scanDelay', text: 'Scan Delay', type: 'number' },
    peakPsi: { field: 'peakPsi', text: 'Peak Psi', type: 'number' },
  },
};
