import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { Api } from '../services/api';
import { storeUser } from '../actions';
import Loading from '../components/loading/Loading';

class PrivateRoute extends Component {
  state = { isVerifyingAuth: true };

  async componentDidMount() {
    const authResponse = await Api.auth.verifyAuth();

    if (authResponse.error) {
      toastr.info('Info', 'Please login');
      this.setState({ isVerifyingAuth: false });
      this.props.history.push('/login')
    } else {
      this.props.userAction.storeUser(authResponse.data);
      this.setState({ isVerifyingAuth: false });
    }
  }

  render() {
    if (this.state.isVerifyingAuth) return <Loading />;

    if (!this.props.user.userData) {
      return <Redirect to="/login" />;
    } else {
      return (
        <Route
          {...this.props.rest}
          render={props => <this.props.component {...props} />}
        />
      );
    }
  }
}

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({
  userAction: bindActionCreators({ storeUser }, dispatch)
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PrivateRoute);
