import React, { Component } from "react";
import { fabric } from "fabric";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setActiveValue,
  storeSeriesFiles,
  setCurrentSeriesIndex,
  updateValue,
  setPlayedSeriesIndex,
  updateActiveSeries,
  updateCustomActiveSeries,
  updateCustomParameters,
  updateCanvasConfigs,
} from "../../actions";
import horizontalImg from "./assets/horizontal-pattern.png";
import verticalImg from "./assets/vertical-pattern.png";

class PlanSlicesImage extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    canvas: null,
    horizontalPattern: new fabric.Pattern({
      source: horizontalImg,
      repeat: "repeat",
    }),
    verticalPattern: new fabric.Pattern({
      source: verticalImg,
      repeat: "repeat",
    }),
    position: 2,
    baseConfig: {
      width: 210,
      height: 200,
      left: 5,
      top: 5,
      fill: "rgba(0,0,0,0)",
      hasControls: true,
      hasRotatingPoint: true,
      selectable: true,
      cornerColor: "cyan",
      cornerSize: 8,
      transparentCorners: false,
      hasBorders: false,
      borderColor: "#ecf76c",
      rotatingPointOffset: 20,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      hide: false,
    },
    rotated: false,
  };

  clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  newRect = () => {
    const scanner = this.props.scanner;
    const serieId = scanner.activeSeries.id;

    const initConfig = scanner.customConfig[`${0}`];

    const customConfig = initConfig || {};

    //initial values
    const maxW = 210;
    const minW = 50;

    const width = this.clamp(maxW * 0.8, minW, maxW);

    //fabric
    let canvas;
    if (this.state.canvas) {
      canvas = this.state.canvas;
    } else {
      canvas = new fabric.Canvas("canvas");
    }

    //save canvas as state if null
    if (!this.state.canvas) this.setState({ canvas });

    fabric.Object.prototype.transparentCorners = false;
    canvas.setWidth(220);
    canvas.setHeight(210);

    const config = {
      ...this.state.baseConfig,
      width: width,
      top: this.state.baseConfig.top + this.state.baseConfig.height / 2,
      left: this.state.baseConfig.left + this.state.baseConfig.width / 2,
      ...customConfig,
      originX: "center",
      originY: "center",
    };

    const configMirror = {
      ...config,
      left: config.top,
      top: config.left,
      width: config.height,
      height: config.width,
      angle: config.angle,
      originX: "center",
      originY: "center",
    };

    var rect;
    if (this.props.seriePlanType === "4" && this.props.planeType === "2") {
      rect = new fabric.Rect(configMirror);
    } else {
      rect = new fabric.Rect(config);
    }

    if (this.props.seriePlanType === "4") {
      if (this.props.planeType === "1" || this.props.planeType === "4") {
        fabric.util.loadImage(horizontalImg, function (img) {
          rect.set(
            "fill",
            new fabric.Pattern({
              source: img,
              repeat: "repeat",
            })
          );
          canvas.renderAll();
        });
      }

      if (this.props.planeType === "2") {
        fabric.util.loadImage(verticalImg, function (img) {
          rect.set(
            "fill",
            new fabric.Pattern({
              source: img,
              repeat: "repeat",
            })
          );

          canvas.renderAll();
        });
      }
    } else {
      if (this.props.seriePlanType === "1") {
        fabric.util.loadImage(horizontalImg, function (img) {
          rect.set(
            "fill",
            new fabric.Pattern({
              source: img,
              repeat: "repeat",
            })
          );
          canvas.renderAll();
        });
      }

      if (this.props.seriePlanType === "2") {
        fabric.util.loadImage(verticalImg, function (img) {
          rect.set(
            "fill",
            new fabric.Pattern({
              source: img,
              repeat: "repeat",
            })
          );

          rect._setOriginToCenter();
          rect._resetOrigin();
          canvas.renderAll();
        });
      }

      if (this.props.seriePlanType === "3") {
        rect.set("hasBorders", true);
        rect.set("stroke", "#ecf76c");
        rect.set("strokeWidth", 3);
        canvas.renderAll();
      }
    }

    canvas.add(rect);
    canvas.on("object:modified", this.onModified);

    canvas.setActiveObject(canvas.item(0));

    return { rect, canvas };
  };

  onModified = (e) => {
    //use config as original state
    //if no prev state for config use default
    const scanner = this.props.scanner;

    const original = scanner.customConfig[`${0}`] || this.state.baseConfig;

    var obj = e.target;

    let w = obj.width * obj.scaleX;
    let h = obj.height * obj.scaleY;

    var rect = obj.getBoundingRect();
    const outerX = rect.width * 0.5;
    const outerY = rect.height * 0.5;

    if (
      rect.left < -outerX ||
      rect.top < -outerY ||
      rect.left + rect.width > 250 + outerX ||
      rect.top + rect.height > 210 + outerY
    ) {
      //reset
      if (obj.angle !== original.angle) {
        //reset angle;
        obj.angle = original.angle;
      } else {
        //reset position and size

        obj.top = original.top;
        obj.left = original.left;
        obj.scaleX = original.scaleX;
        obj.scaleY = original.scaleY;
      }
    }

    const coords = obj.calcCoords();

    //save config on modifed on autoview, config for slide
    let fill = e.target.fill === "rgb(0,0,0)" ? "rgba(0,0,0,0)" : e.target.fill;

    if (this.props.seriePlanType === "4" && this.props.planeType === "2") {
      this.props.scanActions.updateCanvasConfigs({
        top: parseInt(obj.left),
        left: obj.top,
        height: parseInt(w),
        width: parseInt(h),
        scaleX: 1,
        scaleY: 1,
        fill,
        angle: obj.angle,
        hide: false,
        coords,
        modified: true,
        reset: false,
      });
    } else {
      this.props.scanActions.updateCanvasConfigs({
        top: obj.top,
        left: parseInt(obj.left),
        height: h,
        width: parseInt(w),
        scaleX: 1,
        scaleY: 1,
        fill,
        angle: obj.angle,
        hide: false,
        coords,
        reset: false,
        modified: true,
      });
    }
  };

  removeCanvas() {
    const canvas = this.state.canvas;

    //if no canvas do nothing
    if (!canvas) return null;
    //delete events listener to avoid memeroy leaks
    canvas.__eventListeners = {};

    //delete objects
    const objects = canvas.getObjects();
    for (let i = 0; i < objects.length; i++) {
      canvas.remove(objects[i]);
    }

    canvas.renderAll();
  }

  onKeyPress = (e) => {
    if (
      e.nativeEvent.code === "Delete" ||
      e.nativeEvent.code === "Backspace" ||
      e.nativeEvent.keyCode === 46 ||
      e.nativeEvent.keyCode === 8
    ) {
      this.props.scanActions.updateCanvasConfigs({
        hide: true,
      });
    }
  };

  componentDidMount() {
    //draw rect
    this.newRect();
  }

  componentDidUpdate(prevState, prevProps) {
    //reset rect on slide changes
    if (prevProps.image !== this.props.image) {
      this.removeCanvas();

      this.newRect();
    }
  }

  render() {
    // const scanner = this.props.scanner;
    // const serieId = scanner.activeSeries.id;
    // const id = `${serieId}-${this.props.index}`;
    // const hide = scanner.customConfig[id] && scanner.customConfig[id]['hide'];

    // console.info('hide', hide);
    return (
      <div
        ref={this.ref}
        onKeyUp={(e) => this.onKeyPress(e)}
        tabIndex="0"
        className="plan-slices-dashboard"
      >
        {<canvas id="canvas"></canvas>}
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = (dispatch) => ({
  scanActions: bindActionCreators(
    {
      setActiveValue,
      storeSeriesFiles,
      setCurrentSeriesIndex,
      updateValue,
      setPlayedSeriesIndex,
      updateActiveSeries,
      updateCustomActiveSeries,
      updateCustomParameters,
      updateCanvasConfigs,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSlicesImage);
