import { createActions } from 'redux-actions';

const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';

const {
  changeActiveTab
} = createActions({
  [CHANGE_ACTIVE_TAB]: (activeIndex) => ({ activeIndex })
});

export {
  changeActiveTab,
  CHANGE_ACTIVE_TAB,
};

