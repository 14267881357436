import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { patientName, newExam, updatePressureInjector } from '../../actions';
import { Image, Form, Dropdown, Header } from 'semantic-ui-react';
import proneFeet from '../../images/prone-feet.png';
import proneHead from '../../images/prone-head.png';
import supineHead from '../../images/supine-head.png';
import supineFeet from '../../images/supine-feet.png';
import './style.css';

class PatientInformation extends Component {
  state = {
    patientPosition: 'supine',
    patientOrientation: 'head',
    positionImage: supineHead,
    seriesDesc: '',
    patientId: '',
    patientName: '',
  };

  patientEntries = [
    { text: 'Head First', value: 'head', id: 1 },
    { text: 'Feet First', value: 'feet', id: 2 }
  ];

  patientPositions = [
    { text: 'Supine', value: 'supine', id: 1 },
    { text: 'Prone', value: 'prone', id: 2 }
  ];

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => {
      this.setPositionImage();
    });
  };

  handleChangeState = (e, { name, value }) => {
    this.setState({ [name]: value }, () => {
      this.props.guideActions.patientName({
        ...this.props.steps.info,
        [name]: value
      })
    });
  };

  setPositionImage = () => {
    const { patientPosition, patientOrientation } = this.state;

    if (patientPosition === 'prone' && patientOrientation === 'head')
      this.setState({ positionImage: proneHead });
    else if (patientPosition === 'prone' && patientOrientation === 'feet')
      this.setState({ positionImage: proneFeet });
    else if (patientPosition === 'supine' && patientOrientation === 'feet')
      this.setState({ positionImage: supineFeet });
    else if (patientPosition === 'supine' && patientOrientation === 'head')
      this.setState({ positionImage: supineHead });
  };

  prepareStore = activeSeries => {
    const {
      phase,
      injectionDelay,
      injectionDelay2,
      sflowMlSecideB,
      volumeMl,
      duration,
      scanDelay,
      peakPSI,
      sideA,
      sideB,
      contrastAmount
    } = activeSeries;

    const side = sideA ? 'a' : (sideB ? 'b' : '');

    if (Number(phase) === 1) {
      this.props.scannerActions.updatePressureInjector('firstPhaseSide', side)
      this.props.scannerActions.updatePressureInjector('firstPhaseFlow', Number(sflowMlSecideB))
      this.props.scannerActions.updatePressureInjector('firstPhaseVolume', Number(volumeMl))
      this.props.scannerActions.updatePressureInjector('firstPhaseDuration', Number(duration))

      this.props.scannerActions.updatePressureInjector('secondPhaseSide', '')
      this.props.scannerActions.updatePressureInjector('secondPhaseFlow', 0)
      this.props.scannerActions.updatePressureInjector('secondPhaseVolume', 0)
      this.props.scannerActions.updatePressureInjector('secondPhaseDuration', 0)
    } else {
      this.props.scannerActions.updatePressureInjector('firstPhaseSide', '')
      this.props.scannerActions.updatePressureInjector('firstPhaseFlow', 0)
      this.props.scannerActions.updatePressureInjector('firstPhaseVolume', 0)
      this.props.scannerActions.updatePressureInjector('firstPhaseDuration', 0)

      this.props.scannerActions.updatePressureInjector('secondPhaseSide', side)
      this.props.scannerActions.updatePressureInjector('secondPhaseFlow', Number(sflowMlSecideB))
      this.props.scannerActions.updatePressureInjector('secondPhaseVolume', Number(volumeMl))
      this.props.scannerActions.updatePressureInjector('secondPhaseDuration', Number(duration))
    }

    this.props.scannerActions.updatePressureInjector('contrastOral', contrastAmount)
    this.props.scannerActions.updatePressureInjector('firstPhaseDelay', Number(injectionDelay))
    this.props.scannerActions.updatePressureInjector('secondPhaseDelay', Number(injectionDelay2))

    this.props.scannerActions.updatePressureInjector('scanDelay', Number(scanDelay))
    this.props.scannerActions.updatePressureInjector('peakPsi', Number(peakPSI))
  }

  componentWillReceiveProps(nextProps) {
    const { activeSeries, endExam } = nextProps.scanner;

    if (this.props.scanner.activeSeries.id !== activeSeries.id) {
      // set series name from active series
      this.setState({
        seriesDesc: activeSeries.seriesDesc || '',
      });

      if (this.props.scanner.activeSeries
        && nextProps.scanner.activeSeries
        && this.props.scanner.activeSeries.id !== nextProps.scanner.activeSeries.id)
        this.prepareStore(activeSeries)
      // change patient position and orientation
      let patientPosition = this.patientPositions.find(el => el.id === activeSeries.patientPosition)
      let patientOrientation = this.patientEntries.find(el => el.id === activeSeries.patientEntry)
      this.handleChange(null, {
        name: 'patientPosition',
        value: patientPosition ? patientPosition.value : 'supine'
      });
      this.handleChange(null, {
        name: 'patientOrientation',
        value: patientOrientation ? patientOrientation.value : 'head'
      });
    }
    if (!activeSeries.id && endExam) {
      this.setState({
        patientId: '',
        patientName: ''
      }, () => { this.props.scannerActions.newExam() })
    }
  }

  render() {
    const { isRunning } = this.props.scanner.scan;

    return (
      <Form size="mini">
        <Header
          as="h5"
          content="PATIENT INFORMATION"
          textAlign="center"
        />
        <Form.Input
          name="patientId"
          label="Patient ID"
          readOnly={isRunning}
          placeholder="Patient ID"
          className="custom-form-input"
          onChange={this.handleChangeState}
          value={this.state.patientId}
        />
        <Form.Input
          name="patientName"
          label="Patient Name"
          readOnly={isRunning}
          placeholder="Patient Name"
          className="custom-form-input"
          onChange={this.handleChangeState}
          value={this.state.patientName}
        />
        <div className="custom-form-input">
          <label className="dropdown-label">Patient Position</label>
          <Dropdown
            fluid
            selection
            name="patientPosition"
            options={this.patientPositions}
            onChange={this.handleChange}
            value={this.state.patientPosition}
          />
        </div>
        <div className="custom-form-input">
          <label className="dropdown-label">Patient Entry</label>
          <Dropdown
            fluid
            selection
            name="patientOrientation"
            options={this.patientEntries}
            onChange={this.handleChange}
            value={this.state.patientOrientation}
          />
        </div>
        <Image src={this.state.positionImage} className="patient-position-image" />
        <Form.Input
          name="seriesDesc"
          label="Series Description"
          placeholder="Series"
          className="custom-form-input"
          value={this.state.seriesDesc}
        />
      </Form>
    );
  }
}

const mapStateToProps = ({ scanner, steps }) => ({ scanner, steps });
const mapDispatchToProps = dispatch => ({
  guideActions: bindActionCreators({ patientName }, dispatch),
  scannerActions: bindActionCreators({ newExam, updatePressureInjector }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientInformation);
