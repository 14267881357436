export const parameters = {
    imagingPlane: [
        { key: 1, text: 'Coronal', value: '1' }, //front-back
        { key: 2, text: 'Sagittal', value: '2' }, // side-to-side
        { key: 3, text: 'Axial', value: '3' }
    ],
    reconstructionAlgorithm: [
        { key: 1, text: 'Soft Tissue', value: '1' },
        { key: 2, text: 'Bone', value: '2' }
    ],
}