import { createActions } from 'redux-actions';

const SAVE_TOOLTIPS = 'SAVE_TOOLTIPS';

const {
  saveTooltips
} = createActions({
  [SAVE_TOOLTIPS]: tooltips => tooltips,
});

export {
    saveTooltips,
    SAVE_TOOLTIPS
};
