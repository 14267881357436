import React, { Component } from 'react';
import ScanSound from './ScanSound';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  stopScan,
  cancelScan,
  showNotification,
  storeSeriesFiles,
  setActiveValue,
  setCurrentSeriesIndex,
  updateValue,
  updateGantryTilt
} from '../../actions';
import { prepateTime } from '../../helpers/timeFormat';
import { Progress, Button, Grid } from 'semantic-ui-react';
import { Api } from '../../services/api';

class ScanTiming extends Component {
  state = {
    hasDelay: false,
    totalDelay: 0,
    scanTimeLeft: 15,
    delayTimeLeft: 0,
    totalScanTime: 15,
    scanIntervalId: null,
    delayIntervalId: null
  };

  componentWillReceiveProps(nextProps) {
    const { isRunning: currentPropIsRunning } = this.props.scanner.scan;
    const {
      pressureInjector: { scanDelay },
      scan: { isRunning: nextPropIsRunning }
    } = nextProps.scanner;

    if (!currentPropIsRunning && nextPropIsRunning) {
      if (scanDelay) {
        const delayIntervalId = setInterval(this.startDelay, 100);
        // store delayIntervalId in the state so it can be accessed later
        this.setState({
          delayIntervalId,
          hasDelay: true,
          totalDelay: scanDelay,
          delayTimeLeft: scanDelay
        });
      } else {
        const scanIntervalId = setInterval(this.startScan, 10);
        // store scanIntervalId in the state so it can be accessed later
        this.setState({ scanIntervalId });
      }
    }

    const time = 15; //this.countTotalScanTime();

    if (currentPropIsRunning && !nextPropIsRunning) {
      // clear interval
      clearInterval(this.state.scanIntervalId);
      this.setState({ scanTimeLeft: time });
    }

    this.setState({
      totalScanTime: time,
      scanTimeLeft: time
    })
  }

  componentWillUnmount() {
    // clear interval
    clearInterval(this.state.scanIntervalId);
    clearInterval(this.state.delayIntervalId);
  }

  startScan = () => {
    if (this.state.scanTimeLeft > 0) {
      const scanTimeLeft = (this.state.scanTimeLeft - 0.01).toFixed(2);
      this.setState({ scanTimeLeft });
    } else {
      this.stopScan();

      this.props.filesAction.storeSeriesFiles(this.props.scanner.files.buffImages)
      this.props.scannerAction.setActiveValue(this.props.scanner.currentSeriesIndex)
      this.props.scannerAction.setCurrentSeriesIndex(this.props.scanner.activeSeries.id)
      // this.props.scanActions.updateValue('keyIndex', key);

      if (this.props.planSlices.canvas.autoView !== null) this.props.planSlices.canvas.autoView.clear()
      if (this.props.planSlices.canvas.reconstruction !== null) this.props.planSlices.canvas.reconstruction.clear()

      this.props.scannerAction.updateGantryTilt(0)

      this.saveLogs()
    }
  };

  async saveLogs() {
    await Api.log.addProtocol(this.props.user.activeLogId, this.props.scanner.activeSeries.protocol.id)
    await Api.log.addSequence(this.props.user.activeLogId, this.props.scanner.activeSeries.id)
  }

  countTotalScanTime() {
    const { reductionFactor, frequencyMatrix, phaseMatrix, tr, averages, echoTrainFactor } = this.props.scanner.activeSeries;

    if (!echoTrainFactor)
      return 0;

    const phaseSteps = frequencyMatrix * phaseMatrix / 100;
    let operationTime = tr * averages * phaseSteps / echoTrainFactor;
    if (reductionFactor)
      operationTime = operationTime / reductionFactor;

    const result = isNaN(operationTime) || !operationTime ? 5 : operationTime;

    return result / 1000;
  }

  startDelay = () => {
    if (this.state.delayTimeLeft > 0) {
      const delayTimeLeft = (this.state.delayTimeLeft - 0.1).toFixed(2);

      this.setState({ delayTimeLeft });
    } else {
      // clear delay intreval
      clearInterval(this.state.delayIntervalId);
      // set scan interval
      const scanIntervalId = setInterval(this.startScan, 10);
      this.setState({ scanIntervalId, hasDelay: false });
    }
  };

  stopScan = () => {
    this.props.scannerAction.updateValue('currentAutoviewSlide', 0);
    // clear intervals
    clearInterval(this.state.scanIntervalId);
    clearInterval(this.state.delayIntervalId);
    this.props.scannerAction.stopScan();
  };

  cancelScan = () => {
    // clear intervals
    clearInterval(this.state.scanIntervalId);
    clearInterval(this.state.delayIntervalId);
    this.props.scannerAction.cancelScan();
    this.props.notificationAction.showNotification('Scan canceled!')
  };

  renderDelayProgress = () => {
    let prercent = parseInt(this.state.delayTimeLeft * 100 / this.state.totalDelay);
    console.log(prercent, this.state.delayTimeLeft, this.state.totalDelay)
    return (
      <div>
        <div className="progress-label">
          Scan Delay {100 - prercent}%
        </div>
        <Progress
          color='blue'
          size='tiny'
          className="scan-progress"
          percent={100 - prercent}
        />
      </div>
    );
  };

  renderScanProgress = () => {
    const percent = this.state.scanTimeLeft * 100 / this.state.totalScanTime;
    let progress = parseInt(percent) > 100 ? 100 : parseInt(percent);
    return (
      <div>
        <div className="progress-label">
          Scan Progress {100 - progress}%
        </div>
        <Progress
          color='blue'
          size='tiny'
          className="scan-progress"
          percent={100 - progress}
        />
      </div>
    );
  };

  renderProgress = () => {
    let { hasDelay, delayTimeLeft, scanTimeLeft } = this.state;
    if (hasDelay) {
      delayTimeLeft = prepateTime(Number(delayTimeLeft).toFixed(1));
    } else {
      scanTimeLeft = prepateTime(Number(scanTimeLeft).toFixed(1));
    }

    return (
      <Grid>
        <Grid.Column width={5}>
          <h6>Scan time left: {hasDelay ? delayTimeLeft : scanTimeLeft}sec</h6>
        </Grid.Column>
        <Grid.Column width={9} className="scan-progress-container">
          {hasDelay ? this.renderDelayProgress() : this.renderScanProgress()}
        </Grid.Column>
        <Grid.Column width={1} className="scan-progress-container-ctrl">
          <Button
            className="cancel-btn"
            content="Cancel"
            onClick={this.cancelScan}
          />
        </Grid.Column>
        {!hasDelay && <ScanSound />}
      </Grid>
    );
  };

  render() {
    const {
      scan: { isRunning }
    } = this.props.scanner;

    if (isRunning) {
      return this.renderProgress();
    }

    return <h6>Total scan time: 00:0</h6>;
  }
}

const mapStateToProps = ({ user, scanner, planSlices }) => ({ user, scanner, planSlices });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ stopScan, cancelScan, setActiveValue, setCurrentSeriesIndex, updateValue, updateGantryTilt }, dispatch),
  notificationAction: bindActionCreators({ showNotification }, dispatch),
  filesAction: bindActionCreators({ storeSeriesFiles }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanTiming);
