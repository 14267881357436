import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Input, Select, Button, Grid } from "semantic-ui-react";
import { 
  updateActiveSeries, 
  changeActiveTab, 
  showNotification,
  updateValue 
} from "../../actions";
import InjectorParameterModal from './modals/InjectorParameterModal';
import parameters from './parameters';
import Tooltip from '../tooltip/tooltip';

class ImagingTab extends Component {
  state = { selectedParameter: {}, isInjectorModalOpen: false };

  reconstructionAlgorithmOptions = [
    { key: 1, text: "Soft Tissue", value: "soft tissue" },
    { key: 2, text: "Bone", value: "bone" }
  ];

  fileterOptions = [
    { key: 1, text: "Standard", value: 1 },
    { key: 2, text: "Sharp", value: 2 }
  ];

  plane = {
    '1': 'Coronal',
    '2': 'Sagittal',
    '3': 'Axial'
  }

  handleInjectorParameterClick = parameterKey => {
    const { activeSeries } = this.props.scanner;

    if (activeSeries.id)
      this.setState({
        isInjectorModalOpen: true,
        selectedParameter: parameters.injector[parameterKey]
      });
    else
      this.props.notificationAction.showNotification('Please select protocol first!')
  };

  handleChange = (event, data) => {
    const { target } = event;

    this.props.scannerAction.updateActiveSeries(target.name, target.value);
  };

  handleInjectorClose = (changeTab = false) => {
    this.setState({ isInjectorModalOpen: false });
    if(changeTab)
      this.props.tabsAction.changeActiveTab(2)
  };

  render() {
    const { activeSeries, scan: { isRunning } } = this.props.scanner;
    const activeRecAlg = this.reconstructionAlgorithmOptions.find(el => el.value === activeSeries.reconstructionAlgorithm || el.key === parseInt(activeSeries.reconstructionAlgorithm));
    const activeRecAlgVal = activeRecAlg ? activeRecAlg.value : "";

    const activeFilter = this.fileterOptions.find(el => el.key === parseInt(activeSeries.filter));
    const activeFilterVal = activeFilter ? activeFilter.value : "";

    return (
      <Form className="imaging-form">
      <Grid>
        <Grid.Row>
          <Grid.Column className="label" 
            style={{
              width: '30%'}}>Image Matrix</Grid.Column>
          <Grid.Column style={{
            width: '22%'}}>
            <Tooltip paramKey={'imageMatrix'} trigger={<Form.Field
              control={Input}
              name="imageMatrix"
              className="imaging-field custom-image-input"
              onChange={this.handleChange}
              readOnly={activeSeries.imageMatrix || isRunning}
              value={activeSeries.imageMatrix || ""}
            />} 
            />
          </Grid.Column>
          <Grid.Column width={2} className="label">Plane</Grid.Column>
          <Grid.Column style={{
            width: '30%'}}>
          <Tooltip paramKey={'imagingPlane'} trigger={<Form.Field
              control={Input}
              name="imagingPlane"
              className="imaging-field custom-image-input"
              onChange={this.handleChange}
              readOnly={activeSeries.imagingPlane || isRunning}
              value={this.plane[activeSeries.imagingPlane] || ""}
            />} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{
            width: '30%'}} className="label">Reconstruction Algorithm</Grid.Column>
          <Grid.Column  style={{
            width: '67%'}}>
          <Tooltip paramKey={'reconstructionAlgorithm'} trigger={<Form.Field
              control={Select}
              className="imaging-field custom-image-input select-min"
              value={activeRecAlgVal}
              options={this.reconstructionAlgorithmOptions}
              onChange={(e, { value }) =>
                this.props.scannerAction.updateActiveSeries(
                  "reconstructionAlgorithm",
                  value
                )
              }
            />} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
              <Grid.Column style={{
                width: '30%'}} className="label">Contrast Amount</Grid.Column>
              <Grid.Column   style={{
                width: '22%'}}>
                <Form.Field
                  control={Input}
                  name="contrastAmount"
                  className="imaging-field custom-image-input"
                  onChange={this.handleChange}
                  readOnly={!activeSeries.contrastAmount || isRunning}
                  value={activeSeries.contrastAmount || ""}
                />
              </Grid.Column>
              <Grid.Column width={2} className="label">Contrast Agent</Grid.Column>
              <Grid.Column  style={{
                width: '30%'}}>
                <Form.Field
                  control={Input}
                  name="contrastAgent"
                  className="imaging-field custom-image-input"
                  onChange={this.handleChange}
                  readOnly={!activeSeries.contrastAgent || isRunning}
                  value={activeSeries.contrastAgent || ""}
                />
              </Grid.Column>
        </Grid.Row>
        <Grid.Row>
           <Grid.Column style={{
            width: '30%'}} className="label">Window level</Grid.Column>   
           <Grid.Column style={{
            width: '22%'}}>
            <Form.Field
              control={Input}
              name="windowLevel"
              className="imaging-field custom-image-input"
              onChange={this.handleChange}
              readOnly={activeSeries.windowLevel || isRunning}
              value={activeSeries.windowLevel || ""}
            />
           </Grid.Column>   
           <Grid.Column width={2} className="label">Filter</Grid.Column>   
           <Grid.Column style={{
            width: '30%'}}>
            <Form.Field
              control={Select}
              name="filter"
              className="imaging-field custom-image-input"
              value={activeFilterVal}
              options={this.fileterOptions}
              onChange={(e, { value }) =>
                this.props.scannerAction.updateActiveSeries("filter", value)
              }
            />
           </Grid.Column>  
        </Grid.Row>
        <div className="padding-bottom"/>
      </Grid>
        <div className="contrast-oral-item"> 
          <Button
            disabled={isRunning}
            className="th-parameter-ctrl min-btn"
            content="Pressure Injector"
            onClick={() => this.props.tabsAction.changeActiveTab(2)}
          />
          <Button
            fluid
            className="th-parameter-ctrl min-btn"
            disabled={isRunning}
            onClick={() => this.handleInjectorParameterClick('contrastOral')}
          >
            Oral Contrast
          </Button>
          <Button
            fluid
            className="th-parameter-ctrl min-btn reconstruction"
            disabled={isRunning 
              || this.props.scanner.scannedSeriesIds.length === 0}
            onClick={() => {
              this.props.scannerAction.updateValue('isModalRecOpen', true);
            }}
          >
            Reconstructions
          </Button>
        </div>
        <InjectorParameterModal
          isModalOpen={this.state.isInjectorModalOpen}
          onClose={this.handleInjectorClose}
          selectedParameter={this.state.selectedParameter}
        />       
      </Form>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ updateActiveSeries, updateValue }, dispatch),
  tabsAction: bindActionCreators({ changeActiveTab }, dispatch),
  notificationAction: bindActionCreators({ showNotification }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagingTab);
