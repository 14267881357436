import { createActions } from 'redux-actions';

const PATIENT_NAME = 'PATIENT_NAME';
const PROTOCOL = 'PROTOCOL';
const EDIT_START_SCAN = 'EDIT_START_SCAN';

const {
    patientName,
    protocol,
    editStartScan,
} = createActions({
    [PATIENT_NAME]: info => ({info}),
    [PROTOCOL]: protocol => ({protocol}),
    [EDIT_START_SCAN]: isScanned => ({isScanned})
});

export {
    patientName,
    PATIENT_NAME,
    protocol,
    PROTOCOL,
    editStartScan,
    EDIT_START_SCAN
};