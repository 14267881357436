import BaseApiService from './base';

export class ProtocolApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor() {
    super();
  }

  getAll() {
    return this.request({
      method: 'get',
      url: '/api-v2/protocol',
    });
  }

  getByBodyPart(partOfBody) {
    return this.request({
      method: 'post',
      url: '/api-v2/protocol/partOfBody',
      data: { partOfBody },
    });
  }

  getSeries(protcolId) {
    return this.request({
      method: 'get',
      url: `/api-v2/sequence/protocol/${protcolId}`,
    });
  }
}
