import React, { Component } from "react";
import { fabric } from "fabric";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  canvasUpdateAutoview,
  canvasUpdateReconstruction,
  enablePlanslice,
  disablePlanslice,
} from "../../actions";

const VERTICAL = "vertical";
const HORIZONTAL = "horizontal";

class PlanSlicesImage extends Component {
  state = {
    point: {},
    startPoint: {},
    direction: null,
    renderedPlan: false,

    isBrigthnessLevel: false,
    defaultBrightness: 100,
    defaultContrast: 100,
  };

  constructor(props) {
    super(props);

    this.horizontalPattern = null;
    this.verticalPattern = null;
    this.loadVerticalPattern("img/vertical-pattern.png");
    this.loadHorizontalPattern("img/horizontal-pattern.png");
  }

  prepareCanvas(width, height) {
    // Canvas loader
    let canvas = new fabric.Canvas(this.props.canvasId, {
      selection: false,
    });

    canvas.setHeight(height);
    canvas.setWidth(width);

    // Render rect
    // canvas = this.rectLayout(canvas);

    this.saveCanvas(canvas);

    return canvas;
  }

  removeObjects(canvas, name) {
    let objects = {};
    if (name) objects = canvas.getObjects(name);
    else objects = canvas.getObjects();

    for (let i in objects) canvas.remove(objects[i]);
  }

  saveCanvas(canvas) {
    if (this.props.canvasId === "reconstruction")
      this.props.planSlicesAction.canvasUpdateReconstruction(canvas);
    else this.props.planSlicesAction.canvasUpdateAutoview(canvas);
  }

  offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  drawLines() {
    let { canvas } = this.props.planSlices;
    canvas = canvas[this.props.canvasId];

    const listOfObjects = canvas.getObjects();
    const lastObject = listOfObjects[0];
    // if (lastObject._objects) return;

    const currentcanvasItemHeight = Math.abs(lastObject.height);
    let currentcanvasItemOffsetLeft = Math.abs(lastObject.left);
    let currentcanvasItemOffsetTop = Math.abs(lastObject.top);
    const currentcanvasItemOffsetWidth = Math.abs(lastObject.width);

    if (currentcanvasItemHeight === 0 && currentcanvasItemOffsetWidth === 0)
      return;

    if (lastObject.width < 0)
      currentcanvasItemOffsetLeft = lastObject.left + lastObject.width;
    if (lastObject.height < 0)
      currentcanvasItemOffsetTop = lastObject.top + lastObject.height;

    this.removeObjects(canvas);
    const rect = new fabric.Rect({
      id: "rekt",
      top: currentcanvasItemOffsetTop,
      left: currentcanvasItemOffsetLeft,
      width: currentcanvasItemOffsetWidth,
      height: currentcanvasItemHeight,
      fill:
        (this.props.options && this.props.options.type === HORIZONTAL) ||
        (!this.props.options && this.state.direction === HORIZONTAL)
          ? this.verticalPattern
          : this.horizontalPattern,
      opacity: 0.5,
      hasBorders: false,
      hasControls: false,
      hasRotatingPoint: false,
      centeredRotation: true,
    });

    canvas.add(rect);
    canvas.getObjects().forEach((o) => {
      if (o.id === "rekt") canvas.setActiveObject(o);
    });

    this.saveCanvas(canvas);
  }

  loadVerticalPattern(url) {
    fabric.util.loadImage(url, (img) => {
      this.verticalPattern = new fabric.Pattern({
        source: img,
        repeat: "repeat",
      });
    });
  }

  loadHorizontalPattern(url) {
    fabric.util.loadImage(url, (img) => {
      this.horizontalPattern = new fabric.Pattern({
        source: img,
        repeat: "repeat",
      });
    });
  }

  rectLayout(canvas) {
    const rect = new fabric.Rect({
      fill: "#B2B2B2",
      opacity: 0.6,
      hasBorders: false,
      hasControls: false,
      selectable: false,
      centeredRotation: false,
    });
    canvas.add(rect);

    // Enable/Disable image filter and dragging
    rect.on("mouseover", (e) => {
      const { planeSliceEnable } = this.props.planSlices;
      e.target.lockMovementX = e.target.lockMovementY =
        !planeSliceEnable[this.props.canvasId];
    });

    return canvas;
  }

  // Draw rect by changing widht/height
  drawBaseRect(x, y, w, h) {
    const { direction, startPoint, point, renderedPlan } = this.state;
    let { canvas } = this.props.planSlices;
    let options = {};

    canvas = canvas[this.props.canvasId];

    // Create canvas
    if (canvas === null) canvas = this.prepareCanvas(w, h);

    // Prepare canvas after reload
    if (!canvas.item(0)) {
      canvas = this.rectLayout(canvas);
      this.setState({
        renderedPlan: false,
        // point: {},
        // startPoint: {},
        direction: null,
      });
    }

    if (!startPoint.startXPos || renderedPlan) return;

    let xAxis = Math.abs(x - startPoint.startXPos);
    let yAxis = Math.abs(y - startPoint.startYPos);
    if (Math.abs(xAxis - yAxis) < 15) return;

    let directionVector = xAxis >= yAxis ? HORIZONTAL : VERTICAL;

    if (!direction)
      this.setState({
        direction: directionVector,
      });

    options =
      (this.props.options && this.props.options.type === HORIZONTAL) ||
      (!this.props.options && this.state.direction === HORIZONTAL)
        ? {
            width: x - startPoint.startXPos,
            height: startPoint.height,
            top: 0,
            left: point.x,
          }
        : {
            height: y - startPoint.startYPos,
            width: startPoint.width,
            top: point.y,
            left: 0,
          };

    canvas.item(0).set(options);
    canvas.item(0).setCoords();
    canvas.renderAll();
    this.saveCanvas(canvas);
  }

  changeObjPosition(canvas) {
    let item = canvas.getActiveObject();
    if (!item) item = canvas.getObjects()[0];

    // item.on('mouseover', e => {
    //     const { planeSliceEnable } = this.props.planSlices;
    //     e.target.lockMovementX = e.target.lockMovementY = !planeSliceEnable[this.props.canvasId];
    // });

    // canvas.setActiveObject(item.setCoords()).renderAll();
    this.saveCanvas(canvas);
  }

  changeBrightness = (e, items) => {
    if (this.state.isBrigthnessLevel) {
      const clientX = e.clientX || e.touches[0].clientX;
      const clientY = e.clientY || e.touches[0].clientY;
      const xAxisMove = this.state.startPoint.startXPos - clientX;
      const yAxisMove = this.state.startPoint.startYPos - clientY;
      if (items && items[0]) {
        const item = items[0];
        if (
          e.clientX + 5 >= item.x + item.width ||
          e.clientX - 5 <= item.x ||
          e.clientY + 10 >= item.y + item.height ||
          e.clientY - 5 <= item.y
        ) {
          this.setState({ isBrigthnessLevel: false });
        }
        items.forEach((item) => {
          return (item.style.filter = `invert(15%) brightness(${
            this.state.defaultBrightness - yAxisMove * 5
          }%) contrast(${this.state.defaultContrast - xAxisMove}%)`);
        });
      }
      e.preventDefault();
    }
  };

  onMouseMove = (e) => {
    const { planeSliceEnable } = this.props.planSlices;
    let { canvas } = this.props.planSlices;
    canvas = canvas[this.props.canvasId];
    let objs = [];
    if (canvas) objs = canvas.getObjects();
    if (planeSliceEnable[this.props.canvasId]) {
      if (objs.length > 0) {
        objs[0].lockMovementX = false;
        objs[0].lockMovementY = false;
      }
      const w = e.target
        .closest(".autoview-container")
        .querySelector("img").width;
      const h = e.target
        .closest(".autoview-container")
        .querySelector("img").height;
      this.drawBaseRect(
        e.nativeEvent.offsetX || e.touches[0].clientX,
        e.nativeEvent.offsetY || e.touches[0].clientY,
        w,
        h
      );
    } else {
      if (objs.length > 0) {
        objs[0].lockMovementX = true;
        objs[0].lockMovementY = true;
      }
      const items = e.nativeEvent.target
        .closest(".autoview-container")
        .querySelectorAll("img");
      this.changeBrightness(e, items);
    }
  };

  onMouseDown = (e) => {
    const { planeSliceEnable } = this.props.planSlices;
    let elPosition = {};
    if (!(e.nativeEvent.offsetX ? true : false))
      elPosition = this.offset(document.getElementById(this.props.canvasId));

    if (planeSliceEnable[this.props.canvasId]) {
      this.setState({
        startPoint: {
          startXPos: e.nativeEvent.offsetX || e.touches[0].clientX,
          startYPos: e.nativeEvent.offsetY || e.touches[0].clientY,
          height: e.target.offsetHeight,
          width: e.target.offsetWidth,
        },
        point: {
          x: e.nativeEvent.offsetX || e.touches[0].clientX - elPosition.left,
          y: e.nativeEvent.offsetY || e.touches[0].clientY - elPosition.top,
        },
      });
    } else {
      this.setState({
        isBrigthnessLevel: true,
        startPoint: {
          startXPos: e.clientX || e.touches[0].clientX,
          startYPos: e.clientY || e.touches[0].clientY,
        },
      });
    }
  };

  onMouseUp = () => {
    const { planeSliceEnable } = this.props.planSlices;
    let { canvas } = this.props.planSlices;

    canvas = canvas[this.props.canvasId];

    if (planeSliceEnable[this.props.canvasId]) {
      if (!this.state.renderedPlan) {
        let objects = canvas.getObjects();
        let objW = objects[0].width;
        let objH = objects[0].height;
        objW = objW > 0 ? objW : objW * -1;
        objH = objH > 0 ? objH : objH * -1;
        if (objW < 10 || objH < 10) {
          this.setState({ renderedPlan: true });
          for (let i in objects) canvas.remove(objects[i]);

          return;
        }
      }

      this.setState({
        renderedPlan: true,
      });

      // Change position
      if (canvas.getActiveObject()) {
        this.changeObjPosition(canvas);
      } else {
        if (canvas._objects[0].id !== "rekt") this.drawLines();
      }
    } else {
      this.setState({
        isBrigthnessLevel: false,
      });
    }
  };

  onMouseLeave = () => {
    const { planeSliceEnable } = this.props.planSlices;
    let { canvas } = this.props.planSlices;

    canvas = canvas[this.props.canvasId];

    if (planeSliceEnable[this.props.canvasId]) {
      if (!this.state.renderedPlan) {
        let objects = canvas.getObjects();
        let objW = objects[0].width;
        let objH = objects[0].height;
        objW = objW > 0 ? objW : objW * -1;
        objH = objH > 0 ? objH : objH * -1;
        if (objW < 10 || objH < 10) {
          this.setState({ renderedPlan: true });
          for (let i in objects) canvas.remove(objects[i]);

          return;
        }
      }

      this.setState({
        renderedPlan: true,
      });

      // Change position
      this.changeObjPosition(canvas);
    } else {
      this.setState({
        isBrigthnessLevel: false,
      });
    }
  };

  componentDidMount() {
    let { canvas } = this.props.planSlices;
    canvas = canvas[this.props.canvasId];

    if (canvas !== null) {
      let oldCanvas = this.prepareCanvas(canvas.width, canvas.height);
      let array = canvas.getObjects();
      array.forEach((value) => {
        oldCanvas.add(value);
      });
      canvas = oldCanvas;
      this.saveCanvas(oldCanvas);
    }
  }

  render() {
    return (
      <div
        className="plan-slices-dashboard"
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        onMouseDown={this.onMouseDown}
        onMouseLeave={this.onMouseLeave}
        onTouchStart={(e) => {
          e.preventDefault();
          this.onMouseDown(e);
        }}
        onTouchMove={(e) => {
          e.preventDefault();
          this.onMouseMove(e);
        }}
        onTouchEnd={(e) => {
          e.preventDefault();
          this.onMouseUp();
          this.onMouseLeave();
        }}
      >
        <canvas id={this.props.canvasId} />
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({
  scanner,
  planSlices,
});
const mapDispatchToProps = (dispatch) => ({
  planSlicesAction: bindActionCreators(
    {
      canvasUpdateAutoview,
      canvasUpdateReconstruction,
      enablePlanslice,
      disablePlanslice,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSlicesImage);
