import React from 'react';
import { connect } from 'react-redux';
import { Api } from '../../services/api';
import { bindActionCreators } from 'redux';
import {
  selectSeries,
  storeSeriesFiles,
  disablePlanslice,
  updateActiveSeries,
  addScannedSeriesId,
  setCurrentSeriesIndex,
  updateReconstructionSeries,
  setPlayedSeriesIndex,
  showNotification,
  updateValue
} from '../../actions';
import blackImage from '../../images/blackImage.jpg';
import { Modal, Button, Select, Grid, Dropdown, Input } from 'semantic-ui-react';
import {
  Slide,
  Image,
  Slider,
  ButtonBack,
  ButtonNext,
  CarouselProvider,
} from 'pure-react-carousel';
import './style.css';
import PlanSlices from '../planSlices/planSlicesCtrl';
import PlanSlicesImage from '../planSlices/planSlicesImage';
import { parameters } from './parameters';

const VERTICAL = 'vertical';
const HORIZONTAL = 'horizontal';
const AXIOS = '3';

class ReconstructionsModal extends React.Component {

  state = {
    type: VERTICAL,
    currentSlide: 0,
    disablePlanslicesBtn: false,
    fow: '38.0',
    sliceThickness: '2.0',
    sliceSpacing: '2.0',
    imagingPlane: '1',
    reconstructionAlgorithm: '1',
    isPlaying: false,
    disableBtns: false,
    interval: null,

    autoViewSeriesIds: [],
    currentSeriesIndexDisplayed: 0,
    files: {
      images: []
    }
  }

  handleChange = (e, { name, value }) => {
    if (name === 'imagingPlane' && value === AXIOS) {
      this.setState({
        disablePlanslicesBtn: true
      })
    } else {
      this.setState({
        disablePlanslicesBtn: false
      })
    }

    if (name === 'imagingPlane') {
      let { canvas } = this.props.planSlices;
      canvas = canvas['reconstruction'];
      if (canvas)
        canvas.clear();

      const type = value !== '1' ? HORIZONTAL : VERTICAL;
      this.setState({ type });

      this.props.planSlicesAction.disablePlanslice("reconstruction");
      this.props.scanActions.updateValue('isStartLocation', true)
      this.props.scanActions.updateValue('isEndLocation', true)
    }

    this.setState({
      [name]: value
    })
  };

  onClose = () => {
    this.props.scanActions.storeSeriesFiles(this.state.files);
    this.props.scanActions.setCurrentSeriesIndex(this.state.currentSeriesIndexDisplayed);
    this.props.scanActions.updateValue('currentAutoviewSlide', this.state.currentSlide);
    this.setState({
      fow: '38.0',
      sliceThickness: '2.0',
      sliceSpacing: '2.0'
    })
    this.props.onClose();
  };

  renderSeriesImages = () => {
    let { images } = this.state.files;

    return (
      <CarouselProvider
        dragEnabled={false}
        touchEnabled={false}
        naturalSlideWidth={100}
        naturalSlideHeight={90}
        totalSlides={images.length}
        interval={500}
        isPlaying={this.state.isPlaying}
        currentSlide={this.state.currentSlide}
      >
        <Slider classNameAnimation="stop-end">
          {images.map(image => (
            <Slide index={image.id} key={image.id}>
              <Image
                src={image.path}
              />
            </Slide>
          ))}
        </Slider>
        <div className="autoview-ctrls">
          <ButtonBack className="ui button btn-prev"
            onClick={e => { this.setState({ currentSlide: e._targetInst.return.stateNode.props.currentSlide - 1 }) }}>&lt;</ButtonBack>
          <ButtonNext className="ui button btn-next"
            onClick={e => { this.setState({ currentSlide: e._targetInst.return.stateNode.props.currentSlide + 1 }) }}>&gt;</ButtonNext>
          <PlanSlices
            canvasId="reconstruction"
            hideRotateBtn={true}
            disablePlanslicesBtn={this.state.disablePlanslicesBtn || this.state.disableBtns || this.props.scanner.disableRecCtrls} />
          {this.selectSeries()}
        </div>
      </CarouselProvider>
    );
  };

  renderEmptyImage = () => {
    return (
      <div className="rec-images">
        <img src={blackImage} alt="" />
        <div className="autoview-ctrls">
          <Button className="btn-prev" disabled content="&lt;" />
          <Button className="btn-next" disabled content="&gt;" />
          <PlanSlices hideRotateBtn={true} canvasId="reconstruction" />
          {this.selectSeries()}
        </div>
      </div>
    );
  };

  async selectSeriesImages(key, showErrorMessage = false) {
    const response = await Api.series.getFiles(key);

    if (response.data && response.data.data && response.data.data.images.length === 0 && showErrorMessage)
      this.props.notificationAction.showNotification('Sorry, no images for the selected series!')
    if (response.data && response.data.data)
      this.setState({
        files: response.data.data
      })

    return;
  }

  selectSeries = () => {
    const { series, autoViewSeriesIds } = this.props.scanner;
    let selectSeries = [];
    let disabled = true;

    let { canvas } = this.props.planSlices;
    canvas = canvas['reconstruction'];

    if (series) disabled = false;

    autoViewSeriesIds.forEach((element, key) => {
      selectSeries.push({
        key: element,
        text: key + 1,
        value: element
      });
    });

    return (
      <Select
        name="filter"
        placeholder="Filter"
        disabled={disabled || this.state.disableBtns || this.props.scanner.disableRecCtrls}
        className="scan-series-select"
        value={this.state.currentSeriesIndexDisplayed}
        options={selectSeries}
        onChange={async (e, { value }) => {
          if (canvas)
            canvas.clear();

          this.setState({
            currentSeriesIndexDisplayed: value
          })
          await this.selectSeriesImages(value);
          this.setFirstSlide();
        }
        }
      />
    )
  }

  getSeries = async () => {
    let { canvas } = this.props.planSlices;
    canvas = canvas['reconstruction'];

    const response = await Api.series.getReconstructionSequence(this.props.scanner.activeSeries.protocol.id)

    if (this.props.scanner.reconstructionSeries.length === 0)
      this.props.scanActions.updateReconstructionSeries(response.data);

    const selectedSerie = this.props.scanner.reconstructionSeries.find(el => el.imagingPlane === this.state.imagingPlane && el.reconstructionAlgorithm === this.state.reconstructionAlgorithm)

    if (selectedSerie) {
      await this.selectSeriesImages(selectedSerie.id, true);
      let buff = this.state.autoViewSeriesIds;
      let isInclude = true;
      if (!buff.includes(selectedSerie.id)) {
        buff.push(selectedSerie.id)
        isInclude = false;
      }

      this.setState({
        autoViewSeriesIds: buff,
        currentSeriesIndexDisplayed: selectedSerie.id
      })

      if (!this.state.disableBtns) {
        this.setState({
          currentSlide: 0,
        })
      }

      if (!isInclude) {
        this.setState({ disableBtns: true })
        let slide = 0;
        let interval = setInterval(() => {
          slide = this.changeSlide(slide);
          if (this.state.currentSlide === this.state.files.images.length - 1) {
            clearInterval(this.state.interval)
            this.setState({ disableBtns: false })
          }
        }, 500)

        this.setState({
          interval
        })
      }
    }

    if (!selectedSerie) {
      this.props.notificationAction.showNotification('There are no simulated images that meet this criteria.')
    } else {
      this.props.scanActions.updateValue('isStartLocation', false)
      this.props.scanActions.updateValue('isEndLocation', false)
    }

    if (canvas)
      canvas.clear();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scanner.currentAutoviewSlide !== prevProps.scanner.currentAutoviewSlide) {
      this.setState({
        currentSlide: this.props.scanner.currentAutoviewSlide
      })
    }

    if (!prevProps.isModalOpen && this.props.isModalOpen) {
      this.setState({
        files: this.props.scanner.files,
        autoViewSeriesIds: this.props.scanner.autoViewSeriesIds,
        currentSeriesIndexDisplayed: this.props.scanner.currentSeriesIndexDisplayed
      })
    }

    if (this.props.scanner.endExam) {
      this.props.scanActions.updateActiveSeries('sliceThickness', '');
      this.props.scanActions.updateValue('currentAutoviewSlide', 0);
    }
  }

  changeSlide = slide => {
    this.setState({
      currentSlide: slide
    })

    return slide + 1;
  }

  setFirstSlide() {
    this.setState({ currentSlide: 1 }, () => {
      this.setState({ currentSlide: 0 })
    })
  }

  render() {
    const {
      scan: { isRunning, cancelled },
      files: { images },
    } = this.props.scanner;

    return (
      <Modal
        open={this.props.isModalOpen}
        closeOnDimmerClick={false}
        onClose={this.onClose}
        dimmer={'blurring'}
        className="rec"
        size="mini"
        closeIcon>
        <Modal.Header>Reconstruction</Modal.Header>
        <Modal.Content>
          <Grid columns={2}>
            <Grid.Row stretched>
              <Grid.Column className="rec-actions" width={9} >
                <div className="rec-actions-section">
                  <div className="dd-block">
                    <label className="dd-block-label">Plane</label>
                    <Dropdown
                      fluid
                      selection
                      name="imagingPlane"
                      disabled={this.state.disableBtns}
                      options={parameters.imagingPlane}
                      onChange={this.handleChange}
                      value={this.state.imagingPlane}
                    />
                  </div>
                  <div className="dd-block">
                    <label className="dd-block-label">Reconstruction Algorithm</label>
                    <Dropdown
                      fluid
                      selection
                      name="reconstructionAlgorithm"
                      options={parameters.reconstructionAlgorithm}
                      onChange={this.handleChange}
                      value={this.state.reconstructionAlgorithm}
                    />
                  </div>
                  <div className="sec-inputs">
                    <div className="sec-inputs-element">
                      <span className="action-block-label">FOV</span>
                      <Input
                        type="number"
                        name="fow"
                        value={Number(this.state.fow).toFixed(1)}
                        min="0"
                        step=".1"
                        onChange={this.handleChange} />
                      <span className="action-block-label">&nbsp;&nbsp;cm</span>
                    </div>
                    <div className="sec-inputs-element">
                      <span className="action-block-label">Slice Thickness</span>
                      <Input
                        type="number"
                        name="sliceThickness"
                        value={Number(this.state.sliceThickness).toFixed(1)}
                        min="0"
                        step=".1"
                        onChange={this.handleChange} />
                      <span className="action-block-label">mm</span>
                    </div>
                    <div className="sec-inputs-element">
                      <span className="action-block-label">Slice Spacing</span>
                      <Input
                        type="number"
                        name="sliceSpacing"
                        value={Number(this.state.sliceSpacing).toFixed(1)}
                        min="0"
                        step=".1"
                        onChange={this.handleChange} />
                      <span className="action-block-label">mm</span>
                    </div>
                  </div>
                  <Button
                    onClick={this.getSeries}
                    disabled={this.state.disableBtns}
                  >
                    Apply
                      </Button>
                </div>
              </Grid.Column>
              <Grid.Column className="rec-autoview" width={7} >
                <span className="rec-autoview-label">AUTOVIEW</span>
                <div className="rec-images autoview-container">
                  <PlanSlicesImage canvasId="reconstruction" options={{ type: this.state.type }} />
                  {images.length && !isRunning && !cancelled
                    ? this.renderSeriesImages()
                    : this.renderEmptyImage()}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = dispatch => ({
  scanActions: bindActionCreators({
    addScannedSeriesId,
    selectSeries,
    storeSeriesFiles,
    updateActiveSeries,
    updateReconstructionSeries,
    setCurrentSeriesIndex,
    setPlayedSeriesIndex,
    updateValue
  }, dispatch),
  notificationAction: bindActionCreators({
    showNotification
  }, dispatch),
  planSlicesAction: bindActionCreators({
    disablePlanslice
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReconstructionsModal);