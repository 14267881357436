import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Select } from "semantic-ui-react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";
import { bindActionCreators } from "redux";
import { Api } from "../../services/api";
import blackImage from "../../images/blackImage.jpg";
import {
  selectSeries,
  storeSeriesFiles,
  setCurrentSeriesIndex,
  updateValue,
} from "../../actions";

class AutoViewModal extends React.Component {
  state = {
    startPoint: {},
    isBrigthnessLevel: false,
    defaultBrightness: 100,
    defaultContrast: 100,
    currentSlide: 0,
  };

  renderSeriesImages = () => {
    const { images } = this.props.scanner.files;

    return (
      <CarouselProvider
        dragEnabled={false}
        touchEnabled={false}
        naturalSlideWidth={100}
        naturalSlideHeight={50}
        totalSlides={images.length}
        currentSlide={this.state.currentSlide}
      >
        <Slider classNameAnimation="stop-end">
          {images.map((image) => (
            <Slide index={image.id} key={image.id}>
              <Image src={image.path} />
            </Slide>
          ))}
        </Slider>
        <div className="autoview-ctrls">
          <ButtonBack
            className="ui button btn-prev"
            onClick={(e) => {
              this.setState({
                currentSlide:
                  e._targetInst.return.stateNode.props.currentSlide - 1,
              });
            }}
          >
            &lt;
          </ButtonBack>
          <ButtonNext
            className="ui button btn-next"
            onClick={(e) => {
              this.setState({
                currentSlide:
                  e._targetInst.return.stateNode.props.currentSlide + 1,
              });
            }}
          >
            &gt;
          </ButtonNext>
          {this.selectSeries()}
        </div>
      </CarouselProvider>
    );
  };

  renderEmptyImage = () => {
    return (
      <div className="black-image">
        <img src={blackImage} alt="" />
        <div className="autoview-ctrls">
          <Button className="btn-prev" disabled content="&lt;" />
          <Button className="btn-next" disabled content="&gt;" />
          {this.selectSeries()}
        </div>
      </div>
    );
  };

  async selectSeriesImages(key) {
    const response = await Api.series.getFiles(key);

    if (response.data && response.data.data) {
      this.props.scanActions.storeSeriesFiles(response.data.data);
      this.setState({ currentSlide: 0 });
    }
  }

  selectSeries2 = () => {
    const { series, currentSeriesIndexDisplayed, autoViewSeriesIds } =
      this.props.scanner;
    let selectSeries = [];
    let disabled = true;

    if (series) disabled = false;

    autoViewSeriesIds.forEach((element, key) => {
      selectSeries.push({
        key: key,
        text: key + 1,
        value: element,
      });
    });

    return (
      <Select
        name="filter"
        placeholder="Filter"
        disabled={disabled}
        className="scan-series-select"
        value={currentSeriesIndexDisplayed}
        options={selectSeries}
        onChange={(e, { value }) => {
          this.props.scanActions.setCurrentSeriesIndex(value);
          this.selectSeriesImages(value);
        }}
      />
    );
  };

  selectSeries = () => {
    const { series, autoViewSeriesIds, keyIndex } = this.props.scanner;
    let selectSeries = [];
    let disabled = true;

    if (series) disabled = false;

    autoViewSeriesIds.forEach((element, key) => {
      selectSeries.push({
        key: key,
        text: key + 1,
        value: key,
      });
    });

    return (
      <Select
        name="filter"
        // placeholder="Filter"
        disabled={disabled || this.state.disablePlanslicesBtn}
        className="scan-series-select"
        value={keyIndex}
        options={selectSeries}
        onChange={async (e, { value }) => {
          // const [key, id] = value.split('-')

          const id = autoViewSeriesIds[value];

          this.props.scanActions.setCurrentSeriesIndex(id);
          await this.selectSeriesImages(id);
          this.props.scanActions.updateValue("currentAutoviewSlide", 0);
          this.props.scanActions.updateValue("keyIndex", parseInt(value));
          // this.setState((prev) => ({ ...prev, keyIndex: key }))
        }}
      />
    );
  };

  onClose = () => {
    this.props.scanActions.updateValue(
      "currentAutoviewSlide",
      this.state.currentSlide
    );
    this.props.onClose();
  };

  changeBrightness = (e, items) => {
    if (this.state.isBrigthnessLevel) {
      const clientX = e.clientX || e.touches[0].clientX;
      const clientY = e.clientY || e.touches[0].clientY;
      const xAxisMove = this.state.startPoint.startXPos - clientX;
      const yAxisMove = this.state.startPoint.startYPos - clientY;
      if (items && items[0]) {
        const item = items[0];
        if (
          e.clientX + 5 >= item.x + item.width ||
          e.clientX - 5 <= item.x ||
          e.clientY + 10 >= item.y + item.height ||
          e.clientY - 5 <= item.y
        ) {
          this.setState({ isBrigthnessLevel: false });
        }
        items.forEach((item) => {
          return (item.style.filter = `invert(15%) brightness(${
            this.state.defaultBrightness - yAxisMove * 5
          }%) contrast(${this.state.defaultContrast - xAxisMove}%)`);
        });
      }
      e.preventDefault();
    }
  };

  onMouseMove = (e) => {
    const items = e.nativeEvent.target
      .closest(".content")
      .querySelectorAll("img");
    this.changeBrightness(e, items);
  };

  onMouseDown = (e) => {
    this.setState({
      isBrigthnessLevel: true,
      startPoint: {
        startXPos: e.clientX || e.touches[0].clientX,
        startYPos: e.clientY || e.touches[0].clientY,
      },
    });
  };

  onMouseUp = () => {
    this.setState({ isBrigthnessLevel: false });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.scanner.currentAutoviewSlide !==
      prevProps.scanner.currentAutoviewSlide
    ) {
      this.setState({
        currentSlide: this.props.scanner.currentAutoviewSlide,
      });
    }
  }

  render() {
    const {
      scan: { isRunning, cancelled },
      files: { images },
    } = this.props.scanner;

    return (
      <Modal
        id="autoview-modal"
        className="auto-view-modal"
        open={this.props.isModalOpen}
        closeOnDimmerClick={false}
        onClose={this.onClose}
        dimmer={"blurring"}
        closeIcon
      >
        <Modal.Header>AutoView</Modal.Header>
        <Modal.Content>
          {images.length && !isRunning && !cancelled
            ? this.renderSeriesImages()
            : this.renderEmptyImage()}
          <div
            className="dashboard"
            onMouseDown={(e) => this.onMouseDown(e)}
            onMouseMove={(e) => this.onMouseMove(e)}
            onMouseUp={(e) => this.onMouseUp(e)}
            onMouseLeave={(e) => this.onMouseUp(e)}
            onTouchStart={(e) => {
              e.preventDefault();
              this.onMouseDown(e);
            }}
            onTouchMove={(e) => {
              e.preventDefault();
              this.onMouseMove(e);
            }}
            onTouchEnd={(e) => {
              e.preventDefault(e);
              this.onMouseUp(e);
            }}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = (dispatch) => ({
  scanActions: bindActionCreators(
    {
      selectSeries,
      storeSeriesFiles,
      setCurrentSeriesIndex,
      updateValue,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoViewModal);
