import { handleActions } from 'redux-actions';
import { CHANGE_ACTIVE_TAB } from '../actions';

const defaultState = { activeIndex: 0 };

export default handleActions(
  {
    [CHANGE_ACTIVE_TAB]: (state, { payload: { activeIndex } }) => {
      return {
        ...state,
        activeIndex
      };
    },
  },
  defaultState
);
