import React, { Component } from "react";
import { connect } from "react-redux";
import { Api } from "../../services/api";
import { bindActionCreators } from "redux";
import PlanSlices from "../planSlices/planSlicesCtrl";
import PlanSlicesImageMRI from "../planSlicesMRI";
import {
  setActiveValue,
  storeSeriesFiles,
  setCurrentSeriesIndex,
  updateValue,
  setPlayedSeriesIndex,
  selectSeries,
  selectPreviousSeries,
} from "../../actions";
import { Header, Button, Select } from "semantic-ui-react";
import "./style.css";
import AutoViewModal from "./AutoViewModal";

class AutoView extends Component {
  state = {
    isModalOpen: false,
    currentSeriesIndex: 0,
    isFirstScan: true,
    isPlaying: false,
    currentSlide: 0,
    disablePlanslicesBtn: false,
    interval: null,
    autoViewSeriesIds: null,
    canvas: [],
    canvasConfig: {},
    currentCanvas: {},
    enablePlanning: false,
    localSerieId: {
      id: [],
      images: [],
      plane: [],
      imagePlanes: [],
      canvas: [],
    },
    keyIndex: 0,
    style: {
      brightness: 100,
      contrast: 100,
      opacity: 0,
    },
    filter: false,
    coods: {
      x: 0,
      y: 0,
    },
  };

  getImagesAfterChangeSeries = async (id) => {
    const response = await Api.series.getFiles(id);

    if (response.data && response.data.data) {
      this.props.scannerAction.storeSeriesFiles(response.data.data);
    }

    return;
  };

  async selectSeriesImages(key) {
    const response = await Api.series.getFiles(key);

    if (response.data && response.data.data) {
      this.props.scanActions.storeSeriesFiles(response.data.data);
    }
  }

  selectSeries = () => {
    const { series, autoViewSeriesIds, keyIndex } = this.props.scanner;
    let selectSeries = [];
    let disabled = true;

    if (series) disabled = false;

    autoViewSeriesIds.forEach((element, key) => {
      selectSeries.push({
        key: key,
        text: key + 1,
        value: key,
      });
    });

    return (
      <Select
        name="filter"
        // placeholder="Filter"
        disabled={disabled || this.state.disablePlanslicesBtn}
        className="scan-series-select"
        value={keyIndex}
        options={selectSeries}
        onChange={async (e, { value }) => {
          // const [key, id] = value.split('-')

          const id = autoViewSeriesIds[value];

          this.props.scanActions.setCurrentSeriesIndex(id);
          await this.selectSeriesImages(id);
          this.props.scanActions.updateValue("currentAutoviewSlide", 0);
          this.props.scanActions.updateValue("keyIndex", parseInt(value));
          // this.setState((prev) => ({ ...prev, keyIndex: key }))
        }}
      />
    );
  };

  modalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.currentSlide >= this.props.scanner.files.images.length - 1) {
      clearInterval(this.state.interval);
      if (this.state.disablePlanslicesBtn) {
        this.setState({
          disablePlanslicesBtn: false,
        });
        this.props.scanActions.updateValue("disableRecCtrls", false);
      }
    }

    if (
      this.props.scanner.currentAutoviewSlide !==
      prevProps.scanner.currentAutoviewSlide
    ) {
      this.setState({
        currentSlide: this.props.scanner.currentAutoviewSlide,
      });
    }

    if (
      prevProps.scanner.scan.isRunning &&
      !this.props.scanner.scan.isRunning
    ) {
      if (
        this.props.scanner.reconstructionSeriesIdsPlayed.includes(
          this.props.scanner.activeSeries.id
        )
      ) {
        this.playSlides();
      }
    }

    if (
      JSON.stringify(prevProps.scanner.files.images) !==
        JSON.stringify(this.props.scanner.files.images) &&
      this.props.scanner.files.images.length > 0
    ) {
      if (
        !this.props.scanner.reconstructionSeriesIdsPlayed.includes(
          this.props.scanner.activeSeries.id
        )
      ) {
        this.playSlides();
      }
    }
  }

  componentDidMount() {
    const { scannedSeriesIds } = this.props.scanner;
    const { planeSliceEnable } = this.props.planSlices;

    this.setState({
      enablePlanning: planeSliceEnable.autoView,
      scannedSeriesIds,
    });
  }

  playSlides = () => {
    this.setState({
      disablePlanslicesBtn: true,
    });
    this.props.scanActions.updateValue("disableRecCtrls", true);
    this.setFirstSlide();
    this.props.scanActions.setPlayedSeriesIndex(
      this.props.scanner.activeSeries.id
    );
    let slide = 0;
    clearInterval(this.state.interval);
    let interval = setInterval(() => {
      slide = this.changeSlide(slide);
    }, 500);

    this.setState({
      interval,
    });
  };

  changeSlide = (slide) => {
    this.props.scanActions.updateValue("currentAutoviewSlide", slide);

    return slide + 1;
  };

  setFirstSlide() {
    this.setState({ currentSlide: 0 });
  }

  renderSeriesImages = () => {
    const {
      files: { images },
    } = this.props.scanner;
    const { currentSlide } = this.state;
    const totalSlides = images.length;
    var currentSlideImage =
      currentSlide <= totalSlides - 1
        ? images[currentSlide]
        : images[totalSlides - 1];

    return (
      <div className="full-height">
        <div
          onMouseDown={this.handleDown}
          onMouseUp={this.handleUp}
          onMouseMove={this.handleMove}
          onMouseLeave={this.handleLeave}
          onTouchEnd={this.handleLeave}
          draggable={false}
          style={{
            filter: `brightness(${this.state.style.brightness}%) contrast(${this.state.style.contrast}%)`,
          }}
          className="carousel-image"
        >
          {images && (
            <img
              draggable={false}
              src={currentSlideImage.path}
              alt={currentSlideImage.id}
            />
          )}
        </div>
        <div className="autoview-ctrls">
          <Button
            className="ui button btn-prev"
            disabled={currentSlide <= 0}
            onClick={(e) => {
              this.props.scanActions.updateValue(
                "currentAutoviewSlide",
                currentSlide - 1
              );
            }}
          >
            &lt;
          </Button>
          <Button
            className="ui button btn-next"
            disabled={currentSlide >= totalSlides - 1}
            onClick={(e) => {
              this.props.scanActions.updateValue(
                "currentAutoviewSlide",
                currentSlide + 1
              );
            }}
          >
            &gt;
          </Button>
          <PlanSlices
            index={currentSlide}
            canvasId="autoView"
            disablePlanslicesBtn={this.state.disablePlanslicesBtn}
          />
          {this.selectSeries()}
        </div>
      </div>
    );
  };

  renderEmptyImage = () => {
    return (
      <div className="full-height">
        <div className="empty-image" />
        <div className="autoview-ctrls">
          <Button className="btn-prev" disabled content="&lt;" />
          <Button className="btn-next" disabled content="&gt;" />
          <PlanSlices
            canvasId="autoView"
            disablePlanslicesBtn={this.state.disablePlanslicesBtn}
          />
          {this.selectSeries()}
        </div>
      </div>
    );
  };

  showImageBlock() {
    const {
      scan: { isRunning },
      files: { images },
      scannedSeriesIds,
    } = this.props.scanner;

    if (images.length) {
      const seriesIndex = scannedSeriesIds[scannedSeriesIds.length - 1];
      if (this.state.isFirstScan && isRunning) {
        if (this.state.currentSeriesIndex !== seriesIndex)
          this.setState({ currentSeriesIndex: seriesIndex });
        return this.renderEmptyImage();
      } else if (!this.state.isFirstScan && isRunning) {
        if (this.state.currentSeriesIndex !== seriesIndex)
          this.setState({ currentSeriesIndex: seriesIndex });
        return this.renderSeriesImages();
      } else {
        if (this.state.isFirstScan) this.setState({ isFirstScan: false });

        if (this.state.currentSeriesIndex !== seriesIndex)
          this.setState({ currentSeriesIndex: seriesIndex });

        return this.renderSeriesImages();
      }
    } else {
      return this.renderEmptyImage();
    }
  }

  showSlices() {
    const {
      scan: { isRunning },
      series,
      activeSeries,
      scannedSeriesIds,
      currentSeriesIndex,
      currentSeriesIndexDisplayed,
      customConfig,
      keyIndex,
      autoViewSeriesIds,
    } = this.props.scanner;

    const { currentSlide } = this.state;

    // console.log({ currentSlide })
    if (isRunning) return null;
    if (!series.length > 0) return null;
    if (Object.keys(series).length < 2) return null;

    const imagingPlane = series[0]["imagingPlane"];
    const image = series[0].imagesFull[currentSlide];
    const imageFile = series[0].images[currentSlide];

    const initConfig = customConfig[`${0}`];

    // console.log({ currentSlide, image })

    if (initConfig && initConfig.hide === true) return null;
    if (!image) return null;

    if (scannedSeriesIds.length === 0) return null;
    //check if the displayed serie is localizer on next scan and NOT current

    const serieId = autoViewSeriesIds[keyIndex];
    const isDisplayed = keyIndex === 0 && serieId === series[0]["id"];

    const isDisplayedLocalizer =
      currentSeriesIndexDisplayed === series[0]["id"];

    const isActiveSerieLocalizer =
      currentSeriesIndexDisplayed === activeSeries["id"];

    if (!isDisplayed) return null;
    if (isDisplayedLocalizer && !isActiveSerieLocalizer) {
      return (
        <>
          <PlanSlicesImageMRI
            image={imageFile}
            seriePlanType={imagingPlane}
            planeType={image.planeType}
          />
        </>
      );
    }
    if (currentSeriesIndex === 0 && scannedSeriesIds.length > 1) {
      return (
        <>
          <PlanSlicesImageMRI
            image={imageFile}
            seriePlanType={imagingPlane}
            planeType={image.planeType}
          />
        </>
      );
    }
  }

  handleDown = (e) => {
    this.setState({ filter: true });
    console.log("focusing...", {
      x: e.clientX,
      y: e.clientY,
    });

    this.setState({
      coords: {
        x: e.clientX,
        y: e.clientY,
      },
    });
  };

  handleUp = () => {
    this.setState({ filter: false });
    console.log("up...");
  };

  handleMove = (e) => {
    const coords = this.state.coords;
    if (!this.state.filter) return;

    if (e.clientX !== undefined) {
      const xAxisMove = coords.x - e.clientX;
      const yAxisMove = coords.y - e.clientY;
      let brightness = 100 - yAxisMove * 2;
      let contrast = 100 - xAxisMove * 2;
      let opacity = 0 - yAxisMove / 2;

      this.setState({ style: { brightness, contrast, opacity } });
    } else if (e.clientX === undefined) {
      const xAxisMove = this.state.startPoint.startXPos - e.touches[0].clientX;
      const yAxisMove = this.state.startPoint.startYPos - e.touches[0].clientY;
      let brightness = this.state.defaultBrightness - yAxisMove * 2;
      let contrast = this.state.defaultBrightness - xAxisMove * 2;
      let opacity = 0 - yAxisMove / 2;

      this.setState({ style: { brightness, contrast, opacity } });
    }
    e.preventDefault();
  };

  handleLeave = (e) => {
    this.setState({ filter: false });
  };

  render() {
    const { files } = this.props.scanner;

    return (
      <div className="autoview-container">
        <Header as="h5" content="AUTOVIEW" textAlign="center" />
        <Button
          name="search"
          icon="search"
          className="search-bth"
          disabled={files.images.length === 0}
          onClick={() => this.setState({ isModalOpen: true })}
        />
        {this.showSlices()}
        {this.showImageBlock()}
        <AutoViewModal
          isModalOpen={this.state.isModalOpen}
          onClose={this.modalClose}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, planSlices }) => ({ scanner, planSlices });
const mapDispatchToProps = (dispatch) => ({
  scanActions: bindActionCreators(
    {
      selectSeries,
      selectPreviousSeries,
      setActiveValue,
      storeSeriesFiles,
      setCurrentSeriesIndex,
      updateValue,
      setPlayedSeriesIndex,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoView);
