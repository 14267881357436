import { createActions } from 'redux-actions';

const CANVAS_UPDATE_AUTOVIEW = 'CANVAS_UPDATE_AUTOVIEW';
const CANVAS_UPDATE_RECONSTRUCTION = 'CANVAS_UPDATE_RECONSTRUCTION';
const ENABLE_PLANSLICE = 'ENABLE_PLANSLICE';
const DISABLE_PLANSLICE = 'DISABLE_PLANSLICE';
const SET_DEFAULT = 'SET_DEFAULT';

const {
  canvasUpdateAutoview,
  canvasUpdateReconstruction,
  enablePlanslice,
  disablePlanslice,
  setDefault
} = createActions({
  [CANVAS_UPDATE_AUTOVIEW]: canvas => ({ canvas }),
  [CANVAS_UPDATE_RECONSTRUCTION]: canvas => ({ canvas }),
  [ENABLE_PLANSLICE]: id => ({ id }),
  [DISABLE_PLANSLICE]: id => ({ id }),
  [SET_DEFAULT]: () => ({})
});

export {
  canvasUpdateAutoview,
  CANVAS_UPDATE_AUTOVIEW,
  canvasUpdateReconstruction,
  CANVAS_UPDATE_RECONSTRUCTION,
  enablePlanslice,
  ENABLE_PLANSLICE,
  disablePlanslice,
  DISABLE_PLANSLICE,
  setDefault,
  SET_DEFAULT
};
