import { handleActions } from 'redux-actions';
import {
  END_EXAM,
  NEW_EXAM,
  STOP_SCAN,
  START_SCAN,
  CANCEL_SCAN,
  UPDATE_VALUE,
  STORE_SERIES,
  SELECT_SERIES,
  SELECT_NEXT_SERIES,
  STORE_SERIES_FILES,
  UPDATE_ACTIVE_SERIES,
  UPDATE_CUSTOM_ACTIVE_SERIES,
  UPDATE_CUSTOM_PARAMETERS,
  UPDATE_CANVAS_CONFIGS,
  CLEAR_CANVAS_CONFIGS,
  SELECT_PREVIOUS_SERIES,
  STOP_CAROUSEL_AUTOPLAY,
  START_CAROUSEL_AUTOPLAY,
  UPDATE_PRESSURE_INJECTOR,
  STORE_SERIES_FILES_BUFF,
  SET_ACTIVE_VALUE,
  SET_CURRENT_SERIES_INDEX,
  UPDATE_RECONSTRUCTION_SERIES,
  ADD_SCANNED_SERIES_ID,
  SET_PLAYED_SERIES_INDEX,
  UPDATE_GANTRY_TILT,
} from '../actions';

const defaultState = {
  series: [],
  protocol: {},
  activeSeries: {},
  customActiveSerie: {},
  customParameters: {},
  scannedCustomConfig: {},
  customConfig: {
    0: {}
  },
  hasNextSeries: false,
  hasPreviousSeries: false,
  currentSeriesIndex: 0,
  currentSeriesIndexDisplayed: 0,
  keyIndex: null,
  currentAutoviewSlide: 0,
  scannedSeriesIds: [],
  autoViewSeriesIds: [],
  reconstructionSeriesIdsPlayed: [],
  files: {
    seriesId: 0,
    images: [],
    buffImages: [],
    sounds: [],
    isPlayingCarousel: true,
  },
  scan: {
    cancelled: false,
    isRunning: false,
  },
  pressureInjector: {
    firstPhaseFlow: '',
    firstPhaseDelay: '',
    firstPhaseVolume: '',
    firstPhaseDuration: '',
    secondPhaseFlow: '',
    secondPhaseDelay: '',
    secondPhaseVolume: '',
    secondPhaseDuration: '',
    contrastOral: '',
    scanDelay: 0,
    peakPsi: '',
    firstPhaseSide: '',
    secondPhaseSide: '',
  },
  endExam: false,
  reconstructionSeries: [],
  isStartLocation: false,
  isEndLocation: false,
  disableRecCtrls: false,
  isModalRecOpen: false,
};

export default handleActions(
  {
    [STORE_SERIES]: (state, { payload: { protocol, series } }) => {
      // make first series as active series
      const activeSeries = series[0];
      const hasNextSeries = series.length > 1;
      const files = {
        images: [],
        sounds: [],
      };

      return {
        ...state,
        series,
        files,
        protocol,
        activeSeries,
        hasNextSeries,
        scannedSeriesIds: [],
        autoViewSeriesIds: [],
        reconstructionSeries: [],
        reconstructionSeriesIdsPlayed: [],
        currentSeriesIndex: 0,
        hasPreviousSeries: false,
      };
    },
    [UPDATE_RECONSTRUCTION_SERIES]: (state, { payload: { series } }) => {
      return {
        ...state,
        reconstructionSeries: series,
      };
    },
    [SELECT_SERIES]: (state, { payload: { nextSeriesIndex } }) => {
      const activeSeries = state.series[nextSeriesIndex];
      const hasNextSeries = state.series.length - 1 > nextSeriesIndex;
      const hasPreviousSeries = nextSeriesIndex !== 0;

      return {
        ...state,
        activeSeries,
        hasNextSeries,
        currentSeriesIndex: nextSeriesIndex,
        // keyIndex: nextSeriesIndex - 1,
        hasPreviousSeries,
      };
    },
    [SET_ACTIVE_VALUE]: (state, { payload: { id } }) => {
      const activeSeries = state.series[id];
      const hasNextSeries = state.series.length - 1 > id;
      const hasPreviousSeries = id !== 0;

      return {
        ...state,
        activeSeries,
        hasNextSeries,
        currentSeriesIndex: id,
        hasPreviousSeries,
      };
    },
    [SET_CURRENT_SERIES_INDEX]: (state, { payload: { id } }) => {
      return {
        ...state,
        currentSeriesIndexDisplayed: id,
      };
    },
    [SET_PLAYED_SERIES_INDEX]: (state, { payload: { id } }) => {
      let reconstructionSeriesIdsPlayed = state.reconstructionSeriesIdsPlayed;
      if (!reconstructionSeriesIdsPlayed.includes(id))
        reconstructionSeriesIdsPlayed.push(id);

      return {
        ...state,
        reconstructionSeriesIdsPlayed,
      };
    },
    [SELECT_NEXT_SERIES]: (state) => {
      // increase current series index by 1
      const currentSeriesIndex = state.currentSeriesIndex + 1;
      const keyIndex = currentSeriesIndex;
      const activeSeries = state.series[currentSeriesIndex];
      const hasNextSeries = state.series.length - 1 > currentSeriesIndex;

      return {
        ...state,
        activeSeries,
        hasNextSeries,
        // currentSeriesIndex,
        hasPreviousSeries: true,
        // keyIndex
      };
    },
    [SELECT_PREVIOUS_SERIES]: (state) => {
      // decrease current series index by 1
      const currentSeriesIndex = state.currentSeriesIndex - 1;
      const keyIndex = currentSeriesIndex;
      const activeSeries = state.series[currentSeriesIndex];
      const hasPreviousSeries = currentSeriesIndex !== 0;

      return {
        ...state,
        activeSeries,
        // currentSeriesIndex,
        hasNextSeries: true,
        hasPreviousSeries,
        // keyIndex
      };
    },
    [STOP_SCAN]: (state) => {
      const scan = { ...state.scan, isRunning: false, cancelled: false };
      let scannedSeriesIds = state.scannedSeriesIds;
      let autoViewSeriesIds = state.autoViewSeriesIds;


      // if exists insrease by 1
      if (!scannedSeriesIds.includes(state.currentSeriesIndex)) {
        scannedSeriesIds.push(state.currentSeriesIndex);
      } else {
        const newIndex = state.currentSeriesIndex + 1
        scannedSeriesIds.push(newIndex);
      }


      // if (!autoViewSeriesIds.includes(state.activeSeries.id))
      autoViewSeriesIds.push(state.activeSeries.id);

      console.log({ autoViewSeriesIds: autoViewSeriesIds.length })
      const newKeyIndex = autoViewSeriesIds.length - 1

      return {
        ...state,
        scannedSeriesIds,
        autoViewSeriesIds,
        scan,
        scannedCustomConfig: state.customConfig,
        keyIndex: newKeyIndex
      };
    },
    [UPDATE_ACTIVE_SERIES]: (state, { payload: { field, value } }) => {
      //update serie
      const activeSeries = { ...state.activeSeries, [field]: value };
      const newSeries = state.series;

      newSeries[state.currentSeriesIndex] = activeSeries;

      return { ...state, series: newSeries, activeSeries };
    },
    [UPDATE_CUSTOM_ACTIVE_SERIES]: (state, { payload: { field, value } }) => {
      const customActiveSerie = { ...state.customActiveSerie, [field]: value };

      return { ...state, customActiveSerie };
    },
    [UPDATE_CANVAS_CONFIGS]: (state, { payload: { value } }) => {
      const serieId = state.activeSeries.id;
      const config = state.customConfig;

      config[`${0}`] = { ...config[`${0}`], ...value };

      return { ...state, customConfig: config };
    },
    [CLEAR_CANVAS_CONFIGS]: (state, { payload: { index, value } }) => {
      const nextState = { ...state }
      nextState.customConfig = {}

      return nextState;
    },
    [UPDATE_CUSTOM_PARAMETERS]: (
      state,
      { payload: { planningId, field, value } }
    ) => {
      const prevPlanning = state.customParameters[planningId] || {};
      const planningParamters = {
        ...prevPlanning,
        [field]: value,
      };

      const data = {
        ...state.customParameters,
        [planningId]: planningParamters,
      };

      return { ...state, customParameters: data };
    },
    [UPDATE_PRESSURE_INJECTOR]: (state, { payload: { field, value } }) => {
      const pressureInjector = { ...state.pressureInjector, [field]: value };

      return { ...state, pressureInjector };
    },
    [START_SCAN]: (state) => {
      const scan = { ...state.scan, isRunning: true, cancelled: false };

      return {
        ...state,
        scan,
      };
    },
    [CANCEL_SCAN]: (state) => {
      const scan = { ...state.scan, isRunning: false, cancelled: true };
      let scannedSeriesIds = state.scannedSeriesIds;
      scannedSeriesIds.pop();

      let autoViewSeriesIds = state.autoViewSeriesIds;
      autoViewSeriesIds.pop();

      return {
        ...state,
        scannedSeriesIds,
        autoViewSeriesIds,
        scan,
      };
    },
    [ADD_SCANNED_SERIES_ID]: (state, { payload: { id } }) => {
      let autoViewSeriesIds = state.autoViewSeriesIds;

      if (!autoViewSeriesIds.includes(id)) autoViewSeriesIds.push(id);

      return {
        ...state,
        autoViewSeriesIds,
      };
    },
    [END_EXAM]: (state) => {
      return {
        ...defaultState,
        endExam: true,
      };
    },
    [NEW_EXAM]: (state) => {
      return {
        ...defaultState,
        endExam: false,
      };
    },
    [STOP_CAROUSEL_AUTOPLAY]: (state) => {
      const files = { ...state.files, isPlayingCarousel: false };

      return {
        ...state,
        files,
      };
    },
    [START_CAROUSEL_AUTOPLAY]: (state) => {
      const files = { ...state.files, isPlayingCarousel: true };

      return {
        ...state,
        files,
      };
    },
    [STORE_SERIES_FILES]: (state, { payload: { data } }) => {
      const files = {
        seriesId: data && data.id ? data.id : state.files.seriesId,
        images: data.images.sort((a, b) =>
          a.position < b.position ? -1 : a.position > b.position ? 1 : 0
        ),
        buffImages: state.files.buffImages,
        sounds: data.sounds.sort((a, b) =>
          a.position < b.position ? -1 : a.position > b.position ? 1 : 0
        ),
        isPlayingCarousel: true,
      };

      return {
        ...state,
        files,
      };
    },
    [STORE_SERIES_FILES_BUFF]: (state, { payload: { data } }) => {
      data.images.sort((a, b) =>
        a.position < b.position ? -1 : a.position > b.position ? 1 : 0
      );
      data.sounds.sort((a, b) =>
        a.position < b.position ? -1 : a.position > b.position ? 1 : 0
      );

      const files = {
        ...state.files,
        buffImages: data,
        sounds: data.sounds,
      };

      return {
        ...state,
        files,
      };
    },
    [UPDATE_VALUE]: (state, { payload: { field, value } }) => {
      return {
        ...state,
        [field]: value,
      };
    },
    [UPDATE_GANTRY_TILT]: (state, { payload: { value } }) => {
      const activeSeries = {
        ...state.activeSeries,
        gantryTilt: value,
      };

      const series = state.series.map((el) => {
        if (el.id === activeSeries.id) {
          el.gantryTilt = value;
        }

        return el;
      });

      return {
        ...state,
        activeSeries,
        series,
      };
    },
  },
  defaultState
);
