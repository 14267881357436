import React from 'react' 
import { Api } from '../../services/api';
import { toastr } from 'react-redux-toastr';

export default class VerifyToken extends React.Component {
    componentDidMount = async () => {
        const response = await Api.auth.verifyRestore(this.props.match.params.token);
        const errorStyle = {className: 'error-style'}
        if (response.error) {
            toastr.error('Error', response.error, errorStyle);
        } else {
            localStorage.setItem('restoreToken', this.props.match.params.token)
            this.props.history.push('/newpassword');
        }
    }
    render () {
        return null
    }
}