import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import renderField, { SelectField } from '../../shared/renderField';
import validate from './validate';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';
import logoImage from '../../images/logo.png';

class Register extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Grid textAlign="center" className="form-grid" verticalAlign="middle">
        <Grid.Column className="form-grid-column">
          <Image src={logoImage} />
          <Header as="h1" textAlign="center" content="Register" />
          <Form size="large" onSubmit={handleSubmit}>
            <Segment stacked>
              <Field
                name="firstName"
                placeholder="First Name"
                component={renderField}
              />
              <Field
                name="lastName"
                placeholder="Last Name"
                component={renderField}
              />
              <Field
                name="email"
                type="email"
                placeholder="E-mail address"
                component={renderField}
              />
              <Field
                name="organization"
                placeholder="School/Organization"
                options={this.props.options}
                component={SelectField}
              />
              <Field name="city" placeholder="City" component={renderField} />
              <Field name="state" placeholder="State" component={renderField} />
              <Field
                name="country"
                placeholder="Country"
                component={renderField}
              />
              <Field
                name="username"
                placeholder="Username"
                component={renderField}
              />
              <Field
                name="password"
                type="password"
                placeholder="Password"
                component={renderField}
              />
              <Field
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                component={renderField}
              />

              <Button color="teal" fluid size="large">
                Register
              </Button>
            </Segment>
          </Form>
          <Message>
            Already have an account? <Link to="login">Login</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({
  form: 'RegisterForm',
  validate
})(Register);
