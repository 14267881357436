import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import AutoView from '../autoView/AutoView';
import ScanTiming from '../scanTiming/ScanTiming';
import ScannerTabs from '../scannerTabs/ScannerTabs';
import PatientProtocols from '../patientProtocols/PatientProtocols';
import NavigationSection from '../navigationSection/NavigationSection';
import ScannerParameters from '../scannerParameters/ScannerParameters';
import PatientInformation from '../patientInformation/PatientInformation';
import { Api } from '../../services/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveTooltips, updateValue, setActiveLogUser } from '../../actions';
import { toastr } from 'react-redux-toastr';
import Guide from '../guide';
import './style.css';

import Reconstructions from '../reconstruction/reconstrunction';

import NotificationModal from '../notificationModal';

const defaultWidth = 944;
const defaultHeight = 648;

class Home extends Component {

  state = {
    transform: 1,
  }

  getAllTooltips = async () => {
    const responce = await Api.tooltip.getAll();

    if (responce.status === 200) {
      this.props.tooltipAction.saveTooltips(responce.data);
    } else {
      toastr.confirm('Problems appeared while getting tooltips!', {
        disableCancel: true
      });
    }
  }

  handleRecClose = () => {
    this.props.scanActions.updateValue('isModalRecOpen', false);
  };

  floor10 = (value, exp) => {
    return this.decimalAdjust('floor', value, exp);
  };


  decimalAdjust(type, value, exp) {

    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }

    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));

    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  scaleApp = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let scale = Math.min(
      width / defaultWidth,
      height / defaultHeight
    )
    const scaleFix = this.floor10(scale, -1)
    this.setState({ transform: scaleFix })
    document.querySelectorAll('.modal').forEach(value => {
      value.style.transform = `scale(${scaleFix})`;
      value.style.transformOrigin = 'center';
    })
  }

  componentDidMount() {
    this.getAllTooltips();
    const logUserId = localStorage.getItem('logUserId');
    if (logUserId) {
      this.props.userAction.setActiveLogUser(logUserId);
    }

    this.scaleApp();
    window.addEventListener('resize', () => this.scaleApp());

    let targetNode = document.querySelector("body");
    let observerOptions = {
      childList: true,
      attributes: false,
      subtree: true
    }

    let observer = new MutationObserver(() => {
      document.querySelectorAll('.modal').forEach(value => {
        value.style.transform = `scale(${this.state.transform})`;
        value.style.transformOrigin = 'center';
      })
    });

    observer.observe(targetNode, observerOptions);
  }

  render() {
    const styling = {
      transform: `scale(${this.state.transform})`,
      transformOrigin: 'center'
    }
    return (
      <Container className="home-page" style={styling}>
        <Grid centered stackable>
          <Grid.Row id="first-row">
            <Grid.Column className="top-position navBar-section">
              <NavigationSection />
            </Grid.Column>
            <Grid.Column className="top-position ptInfo-section">
              <PatientInformation />
              <Guide type={1} />
            </Grid.Column>
            <Grid.Column className="top-position ptProtocol-section">
              <Grid stackable id="stackable-section" >
                <Grid.Column id="patient-col" >
                  <PatientProtocols />
                  <Guide type={2} />
                </Grid.Column>
                <Grid.Column id="home-autoview" className="autoView-segment">
                  <AutoView />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row id="second-row">
            <Grid.Column id="scan-parameters" className="scan-params-section">
              <ScannerParameters />
              <Guide type={3} />
            </Grid.Column>
            <Grid.Column id="scan-tabs" className="scan-tabs-section">
              <ScannerTabs />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} id="third-row">
            <Grid.Column className="scanTiming-section">
              <ScanTiming />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <NotificationModal
          onClose={this.onCloseNotification}
          modalOpen={this.props.notification.isOpened}
          text={this.props.notification.text}
        />
        <Reconstructions isModalOpen={this.props.scanner.isModalRecOpen} onClose={this.handleRecClose} />
      </Container>
    );
  }
}

const mapStateToProps = ({ tooltip, notification, scanner }) => ({ tooltip, notification, scanner });
const mapDispatchToProps = dispatch => ({
  tooltipAction: bindActionCreators({ saveTooltips }, dispatch),
  scanActions: bindActionCreators({ updateValue }, dispatch),
  userAction: bindActionCreators({ setActiveLogUser }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
