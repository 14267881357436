import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { protocol, patientName, canvasUpdateReconstruction, clearCanvasConfigs } from '../../actions';
import { Modal, Table, Button } from 'semantic-ui-react';

const SCANNABLE_TYPE = 'Scannable';

class ProtocolsModal extends React.Component {
  state = {
    selectedProtocol: {},
    column: null,
    data: [],
    direction: null,
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state
    let sortedData = [];
    if (column !== clickedColumn) {
      if (clickedColumn === 'sequences')
        sortedData = data.sort((a, b) => (a[clickedColumn].length > b[clickedColumn].length) ? 1 : ((b[clickedColumn].length > a[clickedColumn].length) ? -1 : 0));
      else
        sortedData = data.sort((a, b) => (a[clickedColumn] > b[clickedColumn]) ? 1 : ((b[clickedColumn] > a[clickedColumn]) ? -1 : 0));

      this.setState({
        column: clickedColumn,
        data: sortedData,
        direction: 'ascending',
      })

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  compare(a, b) {
    if (a.last_nom < b.last_nom)
      return -1;
    if (a.last_nom > b.last_nom)
      return 1;
    return 0;
  }


  prepareStore = () => {
    const protocol = this.state.selectedProtocol
    const preparedSequences = [];
    this.props.scannerActions.clearCanvasConfigs()

    protocol.sequences.forEach(el => {
      if (el.type === SCANNABLE_TYPE) {
        preparedSequences.push(el);
      }
    })

    if (this.props.planSlices.canvas['reconstruction']) {
      this.props.planSlices.canvas['reconstruction'].clear();
      this.props.planSlicesAction.canvasUpdateReconstruction(null);
    }

    protocol.sequences = preparedSequences;
    this.props.guideActions.protocol(protocol)
    this.props.onProtocolClick(protocol)
  }

  componentDidMount() {
    this.setState({
      data: this.props.protocols
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.protocols !== this.props.protocols)
      this.setState({
        data: this.props.protocols,
        selectedProtocol: {}
      })
  }

  render() {
    const { column, data, direction } = this.state;

    return (
      <Modal
        open={this.props.modalOpen}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        size="small"
        closeIcon
        className="protocol-modal"
        dimmer={'blurring'}
      >
        <Modal.Header>Select Protocol</Modal.Header>
        <Modal.Content>
          <span className="modal-label-ptcl">Protocols</span>
          <div className="table-content">
            <Table sortable celled fixed singleLine selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{ width: '39%' }}
                    sorted={column === 'partOfBody' ? direction : null}
                    onClick={this.handleSort('partOfBody')}
                  >Anatomical Reference</Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={column === 'name' ? direction : null}
                    onClick={this.handleSort('name')}
                  >Protocol Name</Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{ width: '16%' }}
                    sorted={column === 'sequences' ? direction : null}
                    onClick={this.handleSort('sequences')}
                  >Series</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.length &&
                  data.map((protocol, key) => (
                    <Table.Row
                      key={protocol.id}
                      className={
                        key % 2 === 0 ?
                          (this.state.selectedProtocol.id === protocol.id ? 'gray-row active-row' : 'gray-row')
                          : this.state.selectedProtocol.id === protocol.id ? 'active-row' : ''}
                      onClick={() => this.setState({ selectedProtocol: protocol })}
                    >
                      <Table.Cell textAlign="center">{protocol.partOfBody}</Table.Cell>
                      <Table.Cell textAlign="center">{protocol.name}</Table.Cell>
                      <Table.Cell textAlign="center">{protocol.sequences.filter(el => el.type === SCANNABLE_TYPE).length}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          <div className="modal-params-ctrls custom-btns">
            <Button content="Cancel" onClick={this.props.onClose} />
            <Button
              content="Select"
              disabled={!this.state.selectedProtocol.id}
              onClick={this.prepareStore} />
          </div>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ steps, planSlices }) => ({ steps, planSlices });
const mapDispatchToProps = dispatch => ({
  scannerActions:  bindActionCreators({ clearCanvasConfigs }, dispatch),
  guideActions: bindActionCreators({ protocol, patientName }, dispatch),
  planSlicesAction: bindActionCreators({ canvasUpdateReconstruction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolsModal);