import { Component } from 'react';
import { connect } from 'react-redux';

class ScanSound extends Component {
  state = {
    firstSound: new Audio(),
    secondSound: new Audio()
  };

  componentDidMount() {
    this.playFirstSound();
  }

  componentWillUnmount() {
    this.state.firstSound.pause();
    this.state.secondSound.pause();
  }

  playFirstSound = () => {
    const { sounds } = this.props.scanner.files;

    if (sounds[0] && sounds[0].path) {
      const firstSound = new Audio(sounds[0].path);
      firstSound.addEventListener('ended', this.playSecondSound);
      firstSound.load();
      firstSound.play();
      this.setState({ firstSound });
    }
  };

  playSecondSound = () => {
    const { sounds } = this.props.scanner.files;

    if (sounds[1] && sounds[1].path) {
      const secondSound = new Audio(sounds[1].path);
      secondSound.load();
      secondSound.play();
      this.setState({ secondSound });
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });

export default connect(mapStateToProps)(ScanSound);
