import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from './containers/Home';
import Login from './containers/Login';
import Register from './containers/Register';
import PrivateRoute from './containers/PrivateRoute';
import NotFound from './components/notFound/NotFound';
import Loading from './components/loading/Loading';
import Restore from './components/restore/Restore';
import VerifyToken from './components/restore/VerifyToken';
import NewPassword from './components/restore/newPassword/NewPassword';

import { config } from './config';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'semantic-ui-css/semantic.min.css';
import './app.css';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <PrivateRoute path="/" exact component={Home} />
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/restore" exact component={Restore} />
            <Route path="/verifyToken/:token" exact component={VerifyToken} />          
            <Route path="/newpassword" exact component={NewPassword} />
            <Route path="/admin-app"
            component={() => { 
              window.open(`${process.env.REACT_APP_ADMIN_APP_URL}`, "_blank"); 
              return <Redirect to="/" />;
            }}
            />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
        <Loading />
      </React.Fragment>
    );
  }
}

export default App;
