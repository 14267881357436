export default {
  scanType: {
    field: 'scanType',
    text: 'Scan Type',
    type: 'dropdown',
    options: [
      { key: 1, text: 'Localizer', value: 1 },
      { key: 2, text: 'Axial', value: 2 },
      { key: 3, text: 'Helical', value: 3 },
    ],
  },
  startLocation: {
    field: 'startLocation',
    text: 'Start Location',
    type: 'text',
    disabled: true,
  },
  endLocation: {
    field: 'endLocation',
    text: 'End Location',
    type: 'text',
    disabled: true,
  },
  noOfImages: { field: 'noOfImages', text: 'No. of Images', disabled: true },
  gantryTilt: {
    field: 'gantryTilt',
    text: 'Gantry Tilt',
    type: 'dropdown',
    options: [
      { key: 1, text: 0, value: 0 },
      { key: 2, text: 5, value: 5 },
      { key: 3, text: 10, value: 10 },
      { key: 4, text: 15, value: 15 },
      { key: 5, text: 20, value: 20 },
      { key: 6, text: 25, value: 25 },
      { key: 7, text: 30, value: 30 },
    ],
  },
  fieldOfView: { field: 'fieldOfView', text: 'Field of View', type: 'number' },
  kv: {
    field: 'kv',
    text: 'kV',
    type: 'dropdown',
    options: [
      { key: 1, text: 80, value: 80 },
      { key: 2, text: 100, value: 100 },
      { key: 3, text: 110, value: 110 },
      { key: 4, text: 120, value: 120 },
      { key: 5, text: 130, value: 130 },
      { key: 6, text: 140, value: 140 },
    ],
  },
  ma: { field: 'ma', text: 'mA', type: 'number' },
  exposureTime: {
    field: 'exposureTime',
    text: 'Exposure Time',
    disabled: true,
  },
};
