import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import './style.css';

export default props => {
  const { input, placeholder, type, meta: { touched, error }} = props;
  const showError = touched && error ? true : false;

  return (
    <React.Fragment>
      <Form.Input
        {...input}
        {...props}
        type={type}
        error={showError}
        label={placeholder}
        placeholder={placeholder}
      />
      {showError && <span className="field-error">{error}</span>}
    </React.Fragment>
  );
};

export const SelectField = props => (
  <Form.Field>
    <label {...props}>{props.placeholder}</label>
    <Dropdown selection {...props.input}
              value={props.input.value}
              onChange={(param,data) => props.input.onChange(data.value)}
              placeholder={props.placeholder} 
              options={props.options}
     />
   </Form.Field>
)