export default values => {
  const errors = {};

  if (!values.username) {
    errors.username = 'Username is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password too short (8 characters mininum)';
  }

  return errors;
};
