import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment
} from 'semantic-ui-react';
import renderField from '../../shared/renderField';
import validate from './validate';
import logoImage from '../../images/logo.png';

import './style.css';

class Login extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Grid textAlign="center" className="form-grid">
        <Grid.Column className="form-grid-column">
          <Image src={logoImage} />
          <Header
            as="h1"
            textAlign="center"
            content="Log-in to your account"
          />
          <Form size="large" onSubmit={handleSubmit}>
            <Segment stacked>
              <Field
                name="username"
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Username"
                component={renderField}
              />
              <Field
                name="password"
                type="password"
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                component={renderField}
              />

              <Button color="teal" fluid size="large">
                Login
              </Button>
            </Segment>
          </Form>
          { false && <Message className="sign-up-link">
            Don't have an account? <Link to="register">Sign Up</Link>
          </Message>}
          <Message className='restore-link'>
            Forgot password? <Link to="restore">Restore</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({
  form: 'LoginForm',
  validate
})(Login);
