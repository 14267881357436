import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateActiveSeries } from '../../../actions';
import { Modal, Button, Container, Input } from 'semantic-ui-react';
import parameters from '../parameters';

class ThicknessValueChange extends Component {
  state = { 
      detectorArray: '',
      sliceThickness: '',
      pitch: '',
      speed: ''
    };

  onClose = () => {
    this.setState({ parameterValue: '' });
    this.props.onClose();
  };

  onSave = () => {
    const { param } = this.props;
    this.props.scannerAction.updateActiveSeries(param.key, this.state[param.key]);

    this.onClose();
  };

  componentDidUpdate(prevProps) {
    if(this.props.scanner.activeSeries[this.props.param.key] !== prevProps.scanner.activeSeries[prevProps.param.key]) {
      const { param } = this.props;
      this.setState({
          [param.key]: this.props.scanner.activeSeries[param.key]
      })
    }    
  }

  render() {
    const { isModalOpen, param } = this.props;
    
    if(!param.key)
        return '';

    return (
      <Modal 
        className={param.key === 'sliceGap' ? "scan-param-modal-th thickness one-val-change slice-gap" : "scan-param-modal-th thickness one-val-change" }
        closeOnDimmerClick={false}
        onClose={this.onClose} 
        open={isModalOpen} 
        dimmer={'blurring'} 
        size="mini" 
        closeIcon>
        <Modal.Header>{ param.key !== 'sliceGap' ? 'Edit Thickness/Speed settings': 'Slice Gap'}</Modal.Header>
        <Modal.Content>
        {
            <Container>
              <span className="modal-label">
                {param.key === 'sliceGap' ? '' : (parameters.thickness[param.key].altText ? parameters.thickness[param.key].altText : parameters.thickness[param.key].text)}
              </span>
                  <div className="tk-ctrls">
                    {
                      (parameters.thickness[param.key].options) ? 
                        parameters.thickness[param.key].options.map(el => 
                          <Button 
                              key={`tk-btn-${el.value}`}
                              className={
                                  this.state[param.key] !== '' && this.state[param.key] == el.value
                                  ? "tk-ctrl-btn active" 
                                  : "tk-ctrl-btn"
                              }
                              onClick={() => this.setState({[param.key]: el.value})}
                          >{el.text}</Button>)
                      : 
                        <Input
                          fluid
                          min="0"
                          type="number"
                          style={{width: '100%', padding: '0 10px'}}
                          value={this.state[param.key]}
                          onChange={(e, { value }) => this.setState({[param.key]: value ? value : 0})}
                        />
                    }
                </div>
                <div className="modal-params-ctrls">
                  <Button onClick={this.onClose} content="Cancel" />
                  <Button content="Save" onClick={this.onSave} />
                </div>                
            </Container>
        }
        </Modal.Content>
        <Modal.Actions>          
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ scanner }) => ({ scanner });
const mapDispatchToProps = dispatch => ({
  scannerAction: bindActionCreators({ updateActiveSeries }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThicknessValueChange);
