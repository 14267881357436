import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Image, Button, Form, Input } from 'semantic-ui-react';
import { signOut, endExam } from '../../actions';
import { Api } from '../../services/api';
import logo from '../../images/logo.png';
import navigationImage from '../../images/navigation-image.png';
import './style.css';

import AdminLoginModal from './components/adminLoginModal';

class NavigationSection extends Component {

  state = {
    goToAdminApp: false,
  }

  signOut = () => {
    Api.auth.logout(this.props.user.userData.customerId, this.props.user.userData.id, this.props.user);
    this.props.scannerAction.endExam();
    this.props.userAction.signOut();
    localStorage.removeItem('logUserId');
    this.props.history.push('/login');
  };

  capitalizeFirstLetter = (string) => 
    string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const { name, partOfBody } = this.props.scanner.protocol;
    if(this.state.goToAdminApp) {
      return <Redirect to="/admin-app" />
    }
    return (
      <div className="navBar-componetn">
        <Image src={logo} className="logoImage" />
        <br />
        <div className="nav-ctrls">
          <Button 
            className="custom-size-btn min-btn"
            onClick={this.signOut}>
            Logout
          </Button>
          <Button 
            className="custom-size-btn min-btn"
              onClick={() => this.setState({ goToAdminApp: true })}
            >
            Administrator
          </Button>

        </div>
        <Image src={navigationImage} className='nav-bar-images' />
        <Form size="tiny">
          <Form.Field
            fluid
            readOnly
            size="small"
            control={Input}
            className="selected-protocol"
            label="Selected Protocol"
            placeholder="Selected Protocol"
            value={name ? `${this.capitalizeFirstLetter(partOfBody)}: ${name}` : ''}
          />
        </Form>
        <AdminLoginModal isOpened={this.state.isModalOpened} onClose={() => this.setState({ isModalOpened: false })} />
      </div>
    );
  }
}

const mapStateToProps = ({ scanner, user }) => ({ scanner, user });
const mapDispatchToProps = dispatch => ({
  userAction: bindActionCreators({ signOut }, dispatch),
  scannerAction: bindActionCreators({ endExam }, dispatch)
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavigationSection);
